import { GridCellEditCommitParams } from "@mui/x-data-grid";
import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import {
  CountryPricingGroup,
  Currency,
  ExpenseEditorViewModel,
  ExpenseExchangeRate,
  ExpenseType,
  OdometerReading,
  OdometerType,
  PurchaseOrder,
  Quotation,
  QuotationItem,
  QuotationItemAddOn,
  QuotationStatus,
  TeamPricingGroup,
} from "../../models/Finance";
import { ISectionStatus } from "../../models/ISectionStatus";
import { StatusFlag } from "../../models/IStatusFlag";
import { IAutocompleteOption } from "../../models/UI";
import {
  CompareExpenseEditorViewModels,
  CompareExpenseExchangeRates,
  CompareQuotationItemAddon,
  CompareQuotationItems,
} from "../../utils/DataGridHelpers";
import {
  AsyncThunkFactoryDelete,
  AsyncThunkFactoryGet,
  AsyncThunkFactoryPost,
  AsyncThunkFactoryPut,
} from "../../utils/NetworkUtils";
import { isNullOrUndefined } from "../../utils/generalHelpers";
import { RootState } from "../store";

export interface IFinanceSliceState {
  currencies: Currency[];
  expensesBatchDates: Date[];
  expenses: ExpenseEditorViewModel[];
  expenseTypes: ExpenseType[];
  expenseExchangeRates: ExpenseExchangeRate[];
  quotationStatuses: QuotationStatus[];
  teamPricingGroups: TeamPricingGroup[];
  countryPricingGroups: CountryPricingGroup[];
  quotations: Quotation[];
  quotation: Quotation;
  purchaseOrders: PurchaseOrder[];
  odometerReadings: OdometerReading[];
  sectionStatuses: {
    currencies: ISectionStatus;
    expensesBatchDates: ISectionStatus;
    expenses: ISectionStatus;
    expenseTypes: ISectionStatus;
    expenseExchangeRates: ISectionStatus;
    quotationStatus: ISectionStatus;
    teamPricingGroup: ISectionStatus;
    countryPricingGroup: ISectionStatus;
    quotations: ISectionStatus;
    quotation: ISectionStatus;
    purchaseOrders: ISectionStatus;
    odometerReadings: ISectionStatus;
  };
}

const initialState: IFinanceSliceState = {
  currencies: [],
  expensesBatchDates: [],
  expenses: [],
  expenseTypes: [],
  expenseExchangeRates: [],
  quotationStatuses: [],
  teamPricingGroups: [],
  countryPricingGroups: [],
  quotations: [],
  quotation: {
    id: "",
    currencyId: 0,
    exchangeRate: 0,
    name: "",
    note: "",
    profitMargin: 18,
    projectId: "",
    quotationStatus: QuotationStatus.New,
    quotationItems: [],
    quotationItemAddOns: [],
    lastModifiedBy: 0,
    lastModifiedDate: new Date(),
  } as Quotation,
  purchaseOrders: [],
  odometerReadings: [],
  sectionStatuses: {
    currencies: { status: "idle", error: undefined },
    expensesBatchDates: { status: "idle", error: undefined },
    expenses: { status: "idle", error: undefined },
    expenseTypes: { status: "idle", error: undefined },
    expenseExchangeRates: { status: "idle", error: undefined },
    quotationStatus: { status: "idle", error: undefined },
    teamPricingGroup: { status: "idle", error: undefined },
    countryPricingGroup: { status: "idle", error: undefined },
    quotations: { status: "idle", error: undefined },
    quotation: { status: "idle", error: undefined },
    purchaseOrders: { status: "idle", error: undefined },
    odometerReadings: { status: "idle", error: undefined },
  },
};

export const getCurrencies = AsyncThunkFactoryGet<Currency[]>(
  "finance/getCurrencies",
  "finance/currencies"
);

export const postCurrency = AsyncThunkFactoryPost<Currency>(
  "finance/addCurrency",
  "finance/currencies/",
  "Successfully added currency",
  "Failed to add currency"
);

export const putCurrency = AsyncThunkFactoryPut<Currency>(
  "finance/putCurrency",
  "finance/currencies",
  "Successfully updated currency",
  "Failed to update currency"
);

export const deleteCurrency = AsyncThunkFactoryDelete<Currency>(
  "finance/deleteCurrency",
  "finance/currencies",
  "Successfully deleted currency",
  "Failed to delete currency"
);

export const getExpenseBatches = AsyncThunkFactoryGet<Date[]>(
  "finance/getExpenseBatches",
  "expenses/batchdates"
);

export const getExpenseTypes = AsyncThunkFactoryGet<ExpenseType[]>(
  "finance/getExpenseTypes",
  "expenses/expensetypes"
);

export const getExpensesByBatch = AsyncThunkFactoryGet<
  ExpenseEditorViewModel[]
>("getExpensesByBatch", "expenses");

export const putExpensesByBatch = AsyncThunkFactoryPut<
  ExpenseEditorViewModel[]
>(
  "finance/putManyExpensesByBatch",
  "expenses",
  "Successfully updated Expenses",
  "Failed to update Expenses"
);

export const deleteExpensesByBatch = AsyncThunkFactoryDelete(
  "finance/deleteExpensesByBatch",
  "expenses/",
  "Successfully deleted batch",
  "Error deleting batch"
);

export const getExpenseExchangeRates = AsyncThunkFactoryGet<
  ExpenseExchangeRate[]
>("finance/getExpenseExchangeRates", "finance/ExpenseExchangeRate");

export const putManyExpenseExchangeRates = AsyncThunkFactoryPut<
  Partial<ExpenseExchangeRate>[]
>(
  "finance/putManyExpenseExchangeRates",
  "finance/ExpenseExchangeRate",
  "Successfully updated expense exchange rates",
  "Failed to update expense exchange rates"
);

export const getQuotationStatus = AsyncThunkFactoryGet<QuotationStatus[]>(
  "finance/getQuotationStatuses",
  "finance/quotationstatus"
);

export const postQuotationStatus = AsyncThunkFactoryPost<QuotationStatus>(
  "finance/addQuotationStatus",
  "finance/quotationstatus",
  "Successfully added quotation status",
  "Failed to add quotation status"
);

export const putQuotationStatus = AsyncThunkFactoryPut<QuotationStatus>(
  "finance/putQuotationStatus",
  "finance/quotationstatus",
  "Successfully updated quotation status",
  "Failed to update quotation status"
);

export const deleteQuotationStatus = AsyncThunkFactoryDelete<QuotationStatus>(
  "finance/deleteQuotationStatus",
  "finance/quotationstatus",
  "Successfully deleted quotation status",
  "Failed to delete quotation status"
);

export const getTeamPricingGroups = AsyncThunkFactoryGet<TeamPricingGroup[]>(
  "finance/getTeamPricingGroups",
  "finance/teampricinggroups"
);

export const postTeamPricingGroup = AsyncThunkFactoryPost<TeamPricingGroup>(
  "finance/postPricingGroup",
  "finance/teampricinggroups",
  "Successfully added team pricing group",
  "Failed to add team pricing group"
);

export const putTeamPricingGroup = AsyncThunkFactoryPut<TeamPricingGroup>(
  "finance/putPricingGroup",
  "finance/teampricinggroups",
  "Successfully updated team pricing group",
  "Failed to update team pricing group"
);

export const deleteTeamPricingGroup = AsyncThunkFactoryDelete<TeamPricingGroup>(
  "finance/deletePricingGroup",
  "finance/teampricinggroups",
  "Successfully deleted team pricing group",
  "Failed to delete team pricing group"
);

export const getCountryPricingGroups = AsyncThunkFactoryGet<
  CountryPricingGroup[]
>("finance/getCountryPricingGroups", "finance/countrypricinggroups");

export const postCountryPricingGroup =
  AsyncThunkFactoryPost<CountryPricingGroup>(
    "finance/postCountryPricingGroup",
    "finance/countrypricinggroups",
    "Successfully added country pricing group",
    "Failed to add country pricing group"
  );

export const putCountryPricingGroup = AsyncThunkFactoryPut<CountryPricingGroup>(
  "finance/putCountryPricingGroup",
  "finance/countrypricinggroups",
  "Successfully updated country pricing group",
  "Failed to update country pricing group"
);

export const deleteCountryPricingGroup =
  AsyncThunkFactoryDelete<CountryPricingGroup>(
    "finance/deleteCountryPricingGroup",
    "finance/countrypricinggroups",
    "Successfully deleted country pricing group",
    "Failed to delete country pricing group"
  );

export const getQuotation = AsyncThunkFactoryGet<Quotation>(
  "finance/getQuotation",
  "finance/quotations"
);

export const getQuotations = AsyncThunkFactoryGet<Quotation[]>(
  "finance/getProjectQuotations",
  "finance/quotations/project"
);

export const getQuotationWithProject = AsyncThunkFactoryGet<Quotation>(
  "finance/getQuotationWithProject",
  "finance/quotations"
);

export const putQuotation = AsyncThunkFactoryPut<Quotation>(
  "finance/updateQuotation",
  "finance/quotations",
  "Successfully updated quotation",
  "Failed to update quotation"
);

export const postQuotation = AsyncThunkFactoryPost<Quotation>(
  "finance/addQuotation",
  "finance/quotations",
  "Successfully added quotation",
  "Failed to add quotation"
);

export const getRequestersPurchaseOrders = AsyncThunkFactoryGet<
  PurchaseOrder[]
>("finance/getPersonsPurchaseOrders", "finance/purchaseorder/requester");

export const getAuthorizersPurchaseOrders = AsyncThunkFactoryGet<
  PurchaseOrder[]
>("finance/getAuthorizersPurchaseOrders", "finance/purchaseorder/authorizer");

export const putPurchaseOrder = AsyncThunkFactoryPut<PurchaseOrder>(
  "finance/putPersonsPurchaseOrders",
  "finance/purchaseorder",
  "Successfully updated purchase order",
  "Failed to update purchase order"
);

export const postPurchaseOrder = AsyncThunkFactoryPost<Partial<PurchaseOrder>>(
  "finance/postPurhaseOrder",
  "finance/purchaseorder",
  "Successfully added purchase order",
  "Failed to add purchase order"
);

export const getOdometerReadings = AsyncThunkFactoryGet<OdometerReading[]>(
  "finance/getOdometerReadings",
  "finance/odometer"
);

export const postOdometerReading = AsyncThunkFactoryPost<
  Partial<OdometerReading>
>(
  "finance/postOdometerReading",
  "finance/odometer",
  "Successfully added odometer reading",
  "Failed to add odometer reading"
);

export const deleteOdometerReading = AsyncThunkFactoryDelete<OdometerReading>(
  "finance/deleteOdometerReading",
  "finance/odometer",
  "Successfully deleted odometer reading",
  "Failed to delete odometer reading"
);

function ActionReducerBuilderGet<T>(
  builder: ActionReducerMapBuilder<IFinanceSliceState>,
  f: any,
  field: keyof IFinanceSliceState,
  statusField: keyof IFinanceSliceState["sectionStatuses"],
  manipulateResult?: (result: T) => any
) {
  builder
    .addCase(f.pending, (state) => {
      state.sectionStatuses[statusField].status = "loading";
    })
    .addCase(f.fulfilled, (state, action) => {
      state.sectionStatuses[statusField].status = "succeeded";
      state[field] =
        manipulateResult !== undefined
          ? manipulateResult(action.payload.result)
          : action.payload.result;
    })
    .addCase(f.rejected, (state, action) => {
      state.sectionStatuses[statusField].status = "error";
      state.sectionStatuses[statusField].error = action.error.message;
    });
}

function ActionReducerBuilderPost<T>(
  builder: ActionReducerMapBuilder<IFinanceSliceState>,
  f: any,
  field: keyof IFinanceSliceState,
  statusField: keyof IFinanceSliceState["sectionStatuses"],
  shouldAppendResult: boolean,
  manipulateResult?: (result: T) => any
) {
  builder
    .addCase(f.pending, (state) => {
      state.sectionStatuses[statusField].status = "loading";
    })
    .addCase(f.fulfilled, (state, action) => {
      state.sectionStatuses[statusField].status = "succeeded";
      if (
        action.payload.status === 201 &&
        action.payload.result &&
        shouldAppendResult
      ) {
        var manipulated =
          manipulateResult !== undefined
            ? manipulateResult(action.payload.result)
            : action.payload.result;

        if (Array.isArray(state[field])) {
          (state[field] as any) = (state[field] as any).concat(manipulated);
        }
      }
    })
    .addCase(f.rejected, (state, action) => {
      state.sectionStatuses[statusField].status = "error";
      state.sectionStatuses[statusField].error = action.error.message;
    });
}

function ActionReducerBuilderDelete<T>(
  builder: ActionReducerMapBuilder<IFinanceSliceState>,
  f: any,
  field: keyof IFinanceSliceState,
  statusField: keyof IFinanceSliceState["sectionStatuses"],
  predicate?: (element: T) => boolean
) {
  builder
    .addCase(f.pending, (state) => {
      state.sectionStatuses[statusField].status = "loading";
    })
    .addCase(f.fulfilled, (state, action) => {
      state.sectionStatuses[statusField].status = "succeeded";
      if (action.payload.status === 204) {
        if (predicate) {
          (state[field] as any[]) = (state[field] as any[]).filter(
            predicate,
            action.meta.arg
          );
        }
      }
    })
    .addCase(f.rejected, (state, action) => {
      state.sectionStatuses[statusField].status = "error";
      state.sectionStatuses[statusField].error = action.error.message;
    });
}

function ActionReducerBuilderPut(
  builder: ActionReducerMapBuilder<IFinanceSliceState>,
  f: any,
  field: keyof IFinanceSliceState,
  statusField: keyof IFinanceSliceState["sectionStatuses"]
) {
  builder
    .addCase(f.pending, (state) => {
      state.sectionStatuses[statusField].status = "loading";
    })
    .addCase(f.fulfilled, (state, action) => {
      state.sectionStatuses[statusField].status = "succeeded";
    })
    .addCase(f.rejected, (state, action) => {
      state.sectionStatuses[statusField].status = "error";
      state.sectionStatuses[statusField].error = action.error.message;
    });
}

export const financeSlice = createSlice({
  name: "Finance",
  initialState,
  reducers: {
    editExpenseEditorViewModel: (
      state,
      action: {
        payload: {
          params: GridCellEditCommitParams;
          originalItem: ExpenseEditorViewModel | undefined;
        };
      }
    ) => {
      const { params, originalItem } = action.payload;
      var row = state.expenses.find((x) => x.expenseId === params.id);
      if (row === undefined) return;
      switch (params.field) {
        case "eventId":
          row.eventId = params.value === 0 ? null : params.value;
          break;
        case "currency":
          row.currency = Number(params.value);
          break;
        case "localAmount":
          row.localAmount = Number(params.value.toFixed(2));
          break;
        case "vatAmount":
          row.vatAmount = Number(params.value.toFixed(2));
          row.vatRate = Number(params.value.toFixed(2)) / row.netAmount;
          row.grossAmount =
            Number(params.value.toFixed(2)) + Number(row.netAmount);
          break;
        case "netAmount":
          row.netAmount = Number(params.value.toFixed(2));
          row.vatRate = Number(params.value) / Number(params.value.toFixed(2));
          row.grossAmount =
            Number(params.value.toFixed(2)) + Number(row.vatAmount);
          break;
        case "expenseTypeId":
          row.expenseTypeId = Number(params.value);
          break;
      }
      if (row !== undefined && !isNullOrUndefined(originalItem)) {
        if (CompareExpenseEditorViewModels(row, originalItem))
          row.statusFlag = StatusFlag.Existing;
        else row.statusFlag = StatusFlag.Modified;
      }

      state.expenses = state.expenses.reduce(
        (acc: ExpenseEditorViewModel[], x: ExpenseEditorViewModel) => {
          if (x.expenseId !== params.id) acc.push(x);
          else acc.push({ ...x, ...row });
          return acc;
        },
        []
      );
    },
    markExpenseEditorViewModelForDelete: (
      state,
      action: { payload: number }
    ) => {
      var activitySchedule = state.expenses.find(
        (x) => x.expenseId === action.payload
      );

      state.expenses = state.expenses.reduce(
        (acc: ExpenseEditorViewModel[], curr) => {
          if (curr.expenseId !== action.payload) acc.push(curr);
          else if (activitySchedule !== undefined) {
            acc.push({ ...curr, statusFlag: StatusFlag.ToRemove });
          }
          return acc;
        },
        [] as ExpenseEditorViewModel[]
      );
    },
    markExpenseEditorViewModelForKeep: (
      state,
      action: {
        payload: { id: number; current: ExpenseEditorViewModel | undefined };
      }
    ) => {
      const { id, current } = action.payload;
      state.expenses = state.expenses.reduce(
        (acc: ExpenseEditorViewModel[], curr) => {
          if (curr.expenseId !== id) acc.push(curr);
          else
            acc.push({
              ...curr,
              statusFlag: CompareExpenseEditorViewModels(curr, current)
                ? StatusFlag.Existing
                : StatusFlag.Modified,
            });
          return acc;
        },
        []
      );
    },
    addExpenseExchangeRate: (state) => {
      var findLowest = Math.min(
        ...state.expenseExchangeRates.map((x) => x.expenseExchangeRateId)
      );
      state.expenseExchangeRates = [
        {
          expenseExchangeRateId: findLowest > 0 ? -1 : findLowest - 1,
          statusFlag: StatusFlag.New,
          currencyFromId: 0,
          currencyToId: 0,
          month: new Date(),
          rate: 0,
        },
        ...state.expenseExchangeRates,
      ];
    },
    editExpenseExchangeRate: (
      state,
      action: {
        payload: {
          params: GridCellEditCommitParams;
          originalItem: ExpenseExchangeRate | undefined;
        };
      }
    ) => {
      const { params, originalItem } = action.payload;
      var row = state.expenseExchangeRates.find(
        (x) => x.expenseExchangeRateId === params.id
      );
      if (row === undefined) return;
      switch (params.field) {
        case "currencyFromId":
          if (row !== undefined) {
            row.currencyFromId = Number(params.value);
          }
          break;
        case "currencyToId":
          if (row !== undefined) {
            row.currencyToId = Number(params.value);
          }
          break;
        case "rate":
          if (row !== undefined) row.rate = parseFloat(params.value.toFixed(6));
          break;
        case "month":
          if (row !== undefined) row.month = params.value;
          break;
      }

      if (row !== undefined && !isNullOrUndefined(originalItem)) {
        if (CompareExpenseExchangeRates(row, originalItem))
          row.statusFlag = StatusFlag.Existing;
        else row.statusFlag = StatusFlag.Modified;
      }

      state.expenseExchangeRates = state.expenseExchangeRates.reduce(
        (acc: ExpenseExchangeRate[], curr) => {
          if (curr.expenseExchangeRateId !== params.id) acc.push(curr);
          else acc.push({ ...curr, ...row });
          return acc;
        },
        []
      );
    },
    markExpenseExchangeRateForDelete: (state, action: { payload: number }) => {
      if (action.payload > 0) {
        var activitySchedule = state.expenseExchangeRates.find(
          (x) => x.expenseExchangeRateId === action.payload
        );

        state.expenseExchangeRates = state.expenseExchangeRates.reduce(
          (acc: ExpenseExchangeRate[], curr) => {
            if (curr.expenseExchangeRateId !== action.payload) acc.push(curr);
            else if (activitySchedule !== undefined)
              acc.push({ ...curr, statusFlag: StatusFlag.ToRemove });
            return acc;
          },
          []
        );
      }
    },
    markExpenseExchangeRateForKeep: (
      state,
      action: {
        payload: { id: number; current: ExpenseExchangeRate | undefined };
      }
    ) => {
      const { id, current } = action.payload;
      state.expenseExchangeRates = state.expenseExchangeRates.reduce(
        (acc: ExpenseExchangeRate[], curr) => {
          if (curr.expenseExchangeRateId !== id) acc.push(curr);
          else
            acc.push({
              ...curr,
              statusFlag: CompareExpenseExchangeRates(curr, current)
                ? StatusFlag.Existing
                : StatusFlag.Modified,
            });
          return acc;
        },
        []
      );
    },
    duplicateExpenseExchangeRate: (state, action: { payload: number }) => {
      var findLowest = Math.min(
        ...state.expenseExchangeRates.map((x) => x.expenseExchangeRateId)
      );
      var duplicatee = state.expenseExchangeRates.find(
        (x) => x.expenseExchangeRateId === action.payload
      );
      if (duplicatee === undefined) return;

      state.expenseExchangeRates = state.expenseExchangeRates.reduce(
        (acc: ExpenseExchangeRate[], curr) => {
          if (curr.expenseExchangeRateId !== action.payload) acc.push(curr);
          else {
            acc.push({
              ...curr,
              statusFlag: StatusFlag.New,
              expenseExchangeRateId: findLowest > 0 ? -1 : findLowest - 1,
            });
            acc.push(curr);
          }
          return acc;
        },
        []
      );
    },
    addQuotationItem: (state) => {
      var findLowest = Math.min(
        ...(state.quotation?.quotationItems ?? []).map((x) => x.id)
      );
      state.quotation!.quotationItems = [
        {
          id: findLowest > 0 ? -1 : findLowest - 1,
          statusFlag: StatusFlag.New,
          activityTypeId: 0,
          duration: 0,
          nonProductiveDuration: 0,
          quantity: 1,
          customerId: 0,
          comment: "",
          teamPricingGroupId: 0,
          teamItemPrice: 0,
          teamItemPriceOverride: 0,
          useTeamItemPriceOverride: false,
          countryPricingGroupId: 0,
          countryItemPrice: 0,
          countryItemPriceOverride: 0,
          useCountryItemPriceOverride: false,
          estimatedExpenses: 0,
          includeExpensesInTotal: true,
          requiresDealerSelection: false,
          requiresPlanning: false,
          showOnInvoice: false,
          showOnQuotation: false,
          adjustment: 0,
          quotationId: "",
        },
        ...(state.quotation?.quotationItems ?? []),
      ];
    },
    editQuotationItem: (
      state,
      action: {
        payload: {
          params: GridCellEditCommitParams;
          externalData: {
            teamPricingGroups: TeamPricingGroup[];
            countryPricingGroups: CountryPricingGroup[];
          };
          original: QuotationItem | undefined;
        };
      }
    ) => {
      const { params, externalData, original } = action.payload;
      var row = state.quotation?.quotationItems.find((x) => x.id === params.id);
      if (row === undefined) return;
      switch (params.field) {
        case "teamPricingGroupId":
          if (row !== undefined) {
            row.teamPricingGroupId = Number(params.value);
            row.teamItemPrice =
              externalData.teamPricingGroups.find(
                (x) => x.id === Number(params.value)
              )?.price ?? 0;
          }
          break;
        case "useTeamItemPriceOverride":
          if (row !== undefined) {
            row.useTeamItemPriceOverride = Boolean(params.value);
          }
          break;
        case "teamItemPriceOverride":
          if (row !== undefined) {
            row.teamItemPriceOverride = Number(params.value);
          }
          break;
        case "countryPricingGroupId":
          if (row !== undefined) {
            row.countryPricingGroupId = Number(params.value);
            row.countryItemPrice =
              externalData.countryPricingGroups.find(
                (x) => x.id === Number(params.value)
              )?.price ?? 0;
          }
          break;
        case "useCountryItemPriceOverride":
          if (row !== undefined) {
            row.useCountryItemPriceOverride = Boolean(params.value);
          }
          break;
        case "countryItemPriceOverride":
          if (row !== undefined) {
            row.countryItemPriceOverride = Number(params.value);
          }
          break;
        case "activityTypeId":
          if (row !== undefined) row.activityTypeId = Number(params.value);
          break;
        case "duration":
          if (row !== undefined) {
            row.duration = Number(params.value);
          }
          break;
        case "nonProductiveDuration":
          if (row !== undefined) {
            row.nonProductiveDuration = Number(params.value);
          }
          break;
        case "quantity":
          if (row !== undefined) {
            row.quantity = Number(params.value);
          }
          break;

        case "estimatedExpenses":
          if (row !== undefined) {
            row.estimatedExpenses = Number(params.value);
          }
          break;

        case "includeExpensesInTotal":
          if (row !== undefined) {
            row.includeExpensesInTotal = params.value;
          }
          break;

        case "customerId":
          if (row !== undefined) row.customerId = Number(params.value);
          break;

        case "adjustment":
          if (row !== undefined) row.adjustment = Number(params.value);
          break;
      }

      if (row !== undefined && !isNullOrUndefined(original)) {
        if (CompareQuotationItems(row, original))
          row.statusFlag = StatusFlag.Existing;
        else row.statusFlag = StatusFlag.Modified;
      }

      state.quotation!.quotationItems = state.quotation!.quotationItems.reduce(
        (acc: QuotationItem[], curr) => {
          if (curr.id !== params.id) acc.push(curr);
          else acc.push({ ...curr, ...row });
          return acc;
        },
        []
      );
    },
    editQuotationItemComment: (
      state,
      action: { payload: { id: number; value: string } }
    ) => {
      const { id, value } = action.payload;
      state.quotation!.quotationItems = state.quotation!.quotationItems.map(
        (x) => {
          if (x.id !== id) return x;
          return { ...x, comment: value };
        }
      );
    },
    markQuotationItemForDelete: (state, action: { payload: number }) => {
      state.quotation!.quotationItems = state.quotation!.quotationItems.reduce(
        (acc, curr) => {
          if (curr.id !== action.payload) acc.push(curr);
          else if (action.payload > 0)
            acc.push({ ...curr, statusFlag: StatusFlag.ToRemove });
          return acc;
        },
        [] as QuotationItem[]
      );
    },
    markQuotationItemForKeep: (
      state,
      action: { payload: { id: number; current: QuotationItem | undefined } }
    ) => {
      const { id, current } = action.payload;
      state.quotation!.quotationItems = state.quotation!.quotationItems.reduce(
        (acc, curr) => {
          if (curr.id !== id) acc.push(curr);
          else
            acc.push({
              ...curr,
              statusFlag: CompareQuotationItems(curr, current)
                ? StatusFlag.Existing
                : StatusFlag.Modified,
            });
          return acc;
        },
        [] as QuotationItem[]
      );
    },
    duplicateQuotationItem: (state, action: { payload: number }) => {
      var findLowest = Math.min(
        ...state.quotation!.quotationItems.map((x) => x.id)
      );
      var duplicatee = state.quotation!.quotationItems.find(
        (x) => x.id === action.payload
      );
      if (duplicatee === undefined) return;

      state.quotation!.quotationItems = state.quotation!.quotationItems.reduce(
        (acc: QuotationItem[], curr) => {
          if (curr.id !== action.payload) acc.push(curr);
          else {
            acc.push({
              ...curr,
              statusFlag: StatusFlag.New,
              id: findLowest > 0 ? -1 : findLowest - 1,
            });
            acc.push(curr);
          }
          return acc;
        },
        []
      );
    },
    addQuotationItemAddon: (state) => {
      var findLowest = Math.min(
        ...(state.quotation?.quotationItemAddOns ?? []).map((x) => x.id)
      );
      state.quotation!.quotationItemAddOns = [
        {
          id: findLowest > 0 ? -1 : findLowest - 1,
          statusFlag: StatusFlag.New,
          quantity: 1,
          customerId: 0,
          comment: "",
          adjustment: 0,
          quotationId: "",
          title: "",
          cost: 0,
          billableYear: new Date().getFullYear(),
        },
        ...(state.quotation?.quotationItemAddOns ?? []),
      ];
    },
    editQuotationItemAddon: (
      state,
      action: {
        payload: {
          params: GridCellEditCommitParams;
          original: QuotationItemAddOn | undefined;
        };
      }
    ) => {
      const { params, original } = action.payload;
      var row = state.quotation?.quotationItemAddOns.find(
        (x) => x.id === params.id
      );
      switch (params.field) {
        case "title":
          if (row !== undefined) row.title = params.value;
          break;
        case "quantity":
          if (row !== undefined) {
            row.quantity = Number(params.value);
          }
          break;
        case "cost":
          if (row !== undefined) {
            row.cost = Number(params.value);
          }
          break;
        case "customerId":
          if (row !== undefined) row.customerId = Number(params.value);
          break;
        case "adjustment":
          if (row !== undefined) row.adjustment = Number(params.value);
          break;
        case "billableMonth":
          if (row !== undefined)
            row.billableMonth =
              params.value != null ? Number(params.value) : null;
          break;
        case "billableYear":
          if (row !== undefined) row.billableYear = Number(params.value);
          break;
      }
      if (row !== undefined && !isNullOrUndefined(original)) {
        if (CompareQuotationItemAddon(row, original))
          row.statusFlag = StatusFlag.Existing;
        else row.statusFlag = StatusFlag.Modified;
      }

      state.quotation!.quotationItemAddOns =
        state.quotation!.quotationItemAddOns.reduce(
          (acc: QuotationItemAddOn[], curr) => {
            if (curr.id !== params.id) acc.push(curr);
            else acc.push({ ...curr, ...row });
            return acc;
          },
          []
        );
    },
    editQuotationItemAddonComment: (
      state,
      action: { payload: { id: number; value: string } }
    ) => {
      const { id, value } = action.payload;
      state.quotation!.quotationItemAddOns =
        state.quotation!.quotationItemAddOns.map((x) => {
          if (x.id !== id) return x;
          return { ...x, comment: value };
        });
    },
    markQuotationItemAddonForDelete: (state, action: { payload: number }) => {
      state.quotation!.quotationItemAddOns =
        state.quotation!.quotationItemAddOns.reduce((acc, curr) => {
          if (curr.id !== action.payload) acc.push(curr);
          else if (action.payload > 0)
            acc.push({ ...curr, statusFlag: StatusFlag.ToRemove });
          return acc;
        }, [] as QuotationItemAddOn[]);
    },
    markQuotationItemAddonForKeep: (
      state,
      action: {
        payload: { id: number; current: QuotationItemAddOn | undefined };
      }
    ) => {
      const { id, current } = action.payload;
      state.quotation!.quotationItemAddOns =
        state.quotation!.quotationItemAddOns.reduce((acc, curr) => {
          if (curr.id !== id) acc.push(curr);
          else
            acc.push({
              ...curr,
              statusFlag: CompareQuotationItemAddon(curr, current)
                ? StatusFlag.Existing
                : StatusFlag.Modified,
            });
          return acc;
        }, [] as QuotationItemAddOn[]);
    },
    duplicateQuotationItemAddon: (state, action: { payload: number }) => {
      var findLowest = Math.min(
        ...state.quotation!.quotationItemAddOns.map((x) => x.id)
      );
      var duplicatee = state.quotation!.quotationItemAddOns.find(
        (x) => x.id === action.payload
      );
      if (duplicatee === undefined) return;

      state.quotation!.quotationItemAddOns =
        state.quotation!.quotationItemAddOns.reduce(
          (acc: QuotationItemAddOn[], curr) => {
            if (curr.id !== action.payload) acc.push(curr);
            else {
              acc.push({
                ...curr,
                statusFlag: StatusFlag.New,
                id: findLowest > 0 ? -1 : findLowest - 1,
              });
              acc.push(curr);
            }

            return acc;
          },
          []
        );
    },
    resetStatus: (state, action) => {
      switch (action.payload) {
        case "currencies":
          state.sectionStatuses.currencies.status = "idle";
          break;
        case "quotationStatus":
          state.sectionStatuses.quotationStatus.status = "idle";
          break;
        case "teamPricingGroup":
          state.sectionStatuses.teamPricingGroup.status = "idle";
          break;
        case "countryPricingGroup":
          state.sectionStatuses.countryPricingGroup.status = "idle";
          break;
        case "quotations":
          state.sectionStatuses.quotations.status = "idle";
          break;
        case "quotation":
          state.sectionStatuses.quotation.status = "idle";
          break;
      }
    },
    resetFinance: (state) => {
      state.sectionStatuses.currencies.status = "idle";
      state.sectionStatuses.quotationStatus.status = "idle";
      state.sectionStatuses.teamPricingGroup.status = "idle";
      state.sectionStatuses.countryPricingGroup.status = "idle";
      state.sectionStatuses.quotations.status = "idle";
      state.sectionStatuses.quotation.status = "idle";
    },
    emptyQuotation: (state) => {
      state.quotation = {
        id: "",
        currencyId: 0,
        exchangeRate: 0,
        name: "",
        note: "",
        profitMargin: 18,
        projectId: "",
        quotationStatus: QuotationStatus.New,
        quotationItems: [],
        quotationItemAddOns: [],
        lastModifiedBy: 0,
        lastModifiedDate: new Date(),
      } as Quotation;
    },
    emptyExpenses: (state) => {
      state.expenses = [];
    },
  },
  extraReducers: (builder) => {
    //GET
    ActionReducerBuilderGet(
      builder,
      getCountryPricingGroups,
      "countryPricingGroups",
      "countryPricingGroup"
    );
    ActionReducerBuilderGet(builder, getCurrencies, "currencies", "currencies");
    ActionReducerBuilderGet(
      builder,
      getExpenseBatches,
      "expensesBatchDates",
      "expensesBatchDates"
    );
    ActionReducerBuilderGet(
      builder,
      getExpensesByBatch,
      "expenses",
      "expenses"
    );
    ActionReducerBuilderGet(
      builder,
      getExpenseTypes,
      "expenseTypes",
      "expenseTypes"
    );
    ActionReducerBuilderGet(
      builder,
      getExpenseExchangeRates,
      "expenseExchangeRates",
      "currencies"
    );
    ActionReducerBuilderGet(builder, getQuotation, "quotation", "quotation");
    ActionReducerBuilderGet(builder, getQuotations, "quotations", "quotations");
    ActionReducerBuilderGet(
      builder,
      getQuotationStatus,
      "quotationStatuses",
      "quotationStatus"
    );
    ActionReducerBuilderGet(
      builder,
      getTeamPricingGroups,
      "teamPricingGroups",
      "teamPricingGroup"
    );
    ActionReducerBuilderGet(
      builder,
      getRequestersPurchaseOrders,
      "purchaseOrders",
      "purchaseOrders"
    );
    ActionReducerBuilderGet(
      builder,
      getAuthorizersPurchaseOrders,
      "purchaseOrders",
      "purchaseOrders"
    );

    //POST
    ActionReducerBuilderPost(
      builder,
      postCountryPricingGroup,
      "countryPricingGroups",
      "countryPricingGroup",
      true
    );
    ActionReducerBuilderPost(
      builder,
      postCurrency,
      "currencies",
      "currencies",
      true
    );
    ActionReducerBuilderPost(
      builder,
      postQuotation,
      "quotation",
      "quotation",
      false
    );
    ActionReducerBuilderPost(
      builder,
      postQuotationStatus,
      "quotationStatuses",
      "quotationStatus",
      true
    );
    ActionReducerBuilderPost(
      builder,
      postTeamPricingGroup,
      "teamPricingGroups",
      "teamPricingGroup",
      true
    );

    ActionReducerBuilderPost(
      builder,
      postPurchaseOrder,
      "purchaseOrders",
      "purchaseOrders",
      true
    );

    //Delete
    ActionReducerBuilderDelete(
      builder,
      deleteCurrency,
      "currencies",
      "currencies",
      function CheckCurrency(this: Currency, element: Currency) {
        return element.id !== this.id;
      }
    );
    ActionReducerBuilderDelete(
      builder,
      deleteCountryPricingGroup,
      "countryPricingGroups",
      "countryPricingGroup",
      function CheckCPG(
        this: CountryPricingGroup,
        element: CountryPricingGroup
      ) {
        return element.id !== this.id;
      }
    );
    ActionReducerBuilderDelete(
      builder,
      deleteExpensesByBatch,
      "expenses",
      "expenses"
    );

    ActionReducerBuilderDelete(
      builder,
      deleteTeamPricingGroup,
      "teamPricingGroups",
      "teamPricingGroup",
      function CheckTPG(this: TeamPricingGroup, element: TeamPricingGroup) {
        return element.id !== this.id;
      }
    );

    ActionReducerBuilderPut(
      builder,
      putCountryPricingGroup,
      "countryPricingGroups",
      "countryPricingGroup"
    );

    ActionReducerBuilderPut(builder, putCurrency, "currencies", "currencies");

    ActionReducerBuilderPut(
      builder,
      putTeamPricingGroup,
      "teamPricingGroups",
      "teamPricingGroup"
    );

    ActionReducerBuilderPut(
      builder,
      putQuotationStatus,
      "quotationStatuses",
      "quotationStatus"
    );

    ActionReducerBuilderPut(builder, putQuotation, "quotation", "quotations");

    ActionReducerBuilderPut(
      builder,
      putExpensesByBatch,
      "expenses",
      "expenses"
    );

    ActionReducerBuilderPut(
      builder,
      putManyExpenseExchangeRates,
      "expenseExchangeRates",
      "expenseExchangeRates"
    );

    ActionReducerBuilderPut(
      builder,
      putPurchaseOrder,
      "purchaseOrders",
      "purchaseOrders"
    );

    ActionReducerBuilderGet(
      builder,
      getOdometerReadings,
      "odometerReadings",
      "odometerReadings"
    );

    ActionReducerBuilderPost(
      builder,
      postOdometerReading,
      "odometerReadings",
      "odometerReadings",
      false
    );

    ActionReducerBuilderDelete(
      builder,
      deleteOdometerReading,
      "odometerReadings",
      "odometerReadings",
      function CheckOR(this: OdometerReading, element: OdometerReading) {
        return element.id !== this.id;
      }
    );
  },
});

export const expenseTypesAsDict = (state: RootState): IAutocompleteOption[] =>
  state.finance.expenseTypes
    .map((x) => ({
      value: x.expenseTypeId,
      label: x.name,
    }))
    .sort((a, b) => a?.label?.localeCompare(b?.label));

export const currenciesAsDict = (state: RootState): IAutocompleteOption[] =>
  state.finance.currencies
    .map((x) => ({
      value: x.id,
      label: `${x.name}`,
    }))
    .sort((a, b) => a?.label?.localeCompare(b?.label));

export const quotationStatusAsDict = () =>
  Object.keys(QuotationStatus)
    .filter((v) => isNaN(Number(v)))
    .map((label) => ({
      value: QuotationStatus[label as keyof typeof QuotationStatus],
      label,
    }))
    .sort((a, b) => a?.label?.localeCompare(b?.label));

export const OdometerTypesAsDict = () =>
  Object.keys(OdometerType)
    .filter((v) => isNaN(Number(v)))
    .map((label) => ({
      value: OdometerType[label as keyof typeof OdometerType],
      label,
    }))
    .sort((a, b) => a?.label?.localeCompare(b?.label));

export const {
  editExpenseEditorViewModel,
  markExpenseEditorViewModelForDelete,
  markExpenseEditorViewModelForKeep,
  addExpenseExchangeRate,
  editExpenseExchangeRate,
  markExpenseExchangeRateForDelete,
  markExpenseExchangeRateForKeep,
  duplicateExpenseExchangeRate,
  editQuotationItemComment,
  addQuotationItem,
  editQuotationItem,
  markQuotationItemForDelete,
  markQuotationItemForKeep,
  duplicateQuotationItem,
  addQuotationItemAddon,
  editQuotationItemAddon,
  markQuotationItemAddonForDelete,
  markQuotationItemAddonForKeep,
  duplicateQuotationItemAddon,
  editQuotationItemAddonComment,
  resetStatus,
  resetFinance,
  emptyQuotation,
  emptyExpenses,
} = financeSlice.actions;

export default financeSlice.reducer;
