import * as React from "react";
import styled from "styled-components/macro";
import { User } from "react-feather";
import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
// import useAuth from "../../hooks/useAuth";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function NavbarUserDropdown() {
  const navigate = useNavigate();
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const { accounts, instance } = useMsal();

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    instance.logoutRedirect(accounts[0]);
  };

  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <User />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem
          onClick={() => {
            navigate("/profile");
            closeMenu();
          }}
        >
          My Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/profile/settings");
            closeMenu();
          }}
        >
          Settings
        </MenuItem>
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
