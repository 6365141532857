import { FC, PropsWithChildren, createContext, useState } from "react";
import {
  IMSGraphPerson,
  IMSGraphPersonIndentifierType,
  MSGraphPersonContextType,
} from "../models/IMSGraph";
import {
  getPersonByAzureId,
  getPersonByName,
  getPhotoByEmail,
} from "../utils/MsGraphUtils";
import { isNullOrUndefined } from "../utils/generalHelpers";

export const MSGraphPersonContext = createContext<
  MSGraphPersonContextType | any
>({});

const MSGraphPersonProvider: FC<PropsWithChildren> = ({ children }) => {
  const [people, setPeople] = useState<IMSGraphPerson[]>([]);

  const anyPersonInState = (
    uniqueIdentifier: string,
    uniqueIdentifierType: keyof IMSGraphPerson
  ) => {
    return people.some((x) => x[uniqueIdentifierType] === uniqueIdentifier);
  };

  async function getProfile(
    uniqueIdentifier: string,
    uniqueIdentifierType: keyof IMSGraphPerson
  ) {
    var person = undefined;

    if (uniqueIdentifierType === IMSGraphPersonIndentifierType.Id) {
      person = (await getPersonByAzureId(uniqueIdentifier)) as IMSGraphPerson;
    } else if (uniqueIdentifierType === IMSGraphPersonIndentifierType.Name) {
      person = await getPersonByName(uniqueIdentifier);
    }

    if (isNullOrUndefined(person)) return;

    if (isNullOrUndefined(person?.mail)) return person;

    person.photo = await getPhotoByEmail(person?.mail);
    return person;
  }

  async function requestPerson(
    uniqueIdentifier: string,
    uniqueIdentifierType: keyof IMSGraphPerson
  ) {
    if (uniqueIdentifier === undefined || uniqueIdentifier === null) return;

    if (anyPersonInState(uniqueIdentifier, uniqueIdentifierType)) return;

    var profile = await getProfile(uniqueIdentifier, uniqueIdentifierType);

    if (isNullOrUndefined(profile)) return;

    if (!anyPersonInState(profile.id, IMSGraphPersonIndentifierType.Id)) {
      setPeople((currentPeople) => [...currentPeople, profile]);
    }
  }

  async function requestPeople(
    uniqueIdentifiers: string[],
    uniqueIdentifierType: keyof IMSGraphPerson
  ) {
    uniqueIdentifiers
      .filter((x) => x !== undefined)
      .map(async (x) => await requestPerson(x, uniqueIdentifierType));
  }

  const value = { people, requestPerson, requestPeople };

  return (
    <MSGraphPersonContext.Provider value={value}>
      {children}
    </MSGraphPersonContext.Provider>
  );
};

export default MSGraphPersonProvider;
