import React from "react";

import { IconButton, Tooltip } from "@mui/material";
import { MessageSquare } from "react-feather";

function NavbarFeedbackButton(props) {
  const { onClick } = props;

  return (
    <React.Fragment>
      <Tooltip title="Feedback">
        <IconButton color="inherit" onClick={onClick} size="large">
          <MessageSquare />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  );
}

export default NavbarFeedbackButton;
