import { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../hooks/TypeScript/hooks";
import {
  Card,
  CardContent,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  CircularProgress,
  Typography,
  Link as MuiLink,
} from "@mui/material";
import { X } from "react-feather";
import {
  updateNotification,
  getNotifications,
} from "../../../../redux/slices/ui";
import { useSnackbar } from "notistack";
import { Link as RouterLink } from "react-router-dom";
import DashboardBasePage from "../../../../components/DashboardBasePage";
import { IResult } from "../../../../utils/NetworkUtils";
import { Notification } from "../../../../models/Core";
import moment from "moment";

function NotificationItem(props: {
  id: number;
  title: string;
  description: string;
  url: string;
  snackbar: any;
  isOld: boolean;
}) {
  const dispatch = useAppDispatch();
  const [beingAcknowledged, setBeingAcknowledged] = useState<boolean>();

  const handleAcknowledge = async () => {
    setBeingAcknowledged(true);
    var ret = await dispatch(
      updateNotification({
        data: "",
        parameter: props.id.toString(),
        enqueueSnackbar: props.snackbar,
      })
    );
    if (!(ret.payload as IResult<Notification>).hasError) {
      dispatch(getNotifications());
    }
  };

  return (
    <ListItem
      divider
      alignItems="center"
      sx={{ justifyContent: "space-around" }}
    >
      <ListItemText
        primary={
          props.url ? (
            <MuiLink
              component={RouterLink}
              to={props.url}
              children={props.title}
            />
          ) : (
            props.title
          )
        }
        primaryTypographyProps={{
          variant: "subtitle2",
          color: "textPrimary",
        }}
        secondary={props.description}
      />
      {!props.isOld ? (
        <Tooltip title="Dismiss">
          <IconButton
            onClick={async () => await handleAcknowledge()}
            disabled={beingAcknowledged}
          >
            {beingAcknowledged ? <CircularProgress /> : <X />}
          </IconButton>
        </Tooltip>
      ) : (
        <></>
      )}
    </ListItem>
  );
}

function Notifications() {
  const notificationsSelect = useAppSelector((x) => x.ui.notifications);
  const thirtyDaysAgo = moment().subtract(30, "day").toDate();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  return (
    <DashboardBasePage
      title="Notifications"
      breadcrumbItems={[{ title: "Notifications", order: 1 }]}
    >
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h4">Current Notifications</Typography>
          <List disablePadding>
            {notificationsSelect
              ?.filter((x) => !x.isAcknowledged)
              .map((notification) => (
                <NotificationItem
                  key={notification.id}
                  id={notification.id}
                  title={notification.subject}
                  description={notification.message}
                  url={notification.url}
                  snackbar={enqueueSnackbar}
                  isOld={notification.isAcknowledged}
                />
              ))}
          </List>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Typography variant="h4">
            Older Notifications (past 30 days)
          </Typography>
          <List disablePadding>
            {notificationsSelect
              ?.filter(
                (x) =>
                  x.isAcknowledged && moment(x.created).isAfter(thirtyDaysAgo)
              )
              .map((notification) => (
                <NotificationItem
                  key={notification.id}
                  id={notification.id}
                  title={notification.subject}
                  description={notification.message}
                  url={notification.url}
                  snackbar={enqueueSnackbar}
                  isOld={notification.isAcknowledged}
                />
              ))}
          </List>
        </CardContent>
      </Card>
    </DashboardBasePage>
  );
}

export default Notifications;
