import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import { getFromLS } from "./utils/LocalStoreHelper";

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    defaultNS: "translation",
    fallbackLng: ["en"], //Comment to see missing per language
    lng: getFromLS("localeCode"),
    languageOptions: ["en", "fr", "de", "nl"],
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{ns}}.{{lng}}.json?lng={{lng}}&{{ns}}`,
    },
    // // Modifies key to visibly show its missing
    // parseMissingKeyHandler: (key, defaultValue) => {
    //   return `//${key}//`;
    // },
  });

export const languageOptions = [
  {
    code: "en",
    mapCode: "gb",
    label: "English",
  },
  {
    code: "fr",
    mapCode: "fr",
    label: "French",
  },
  {
    code: "de",
    mapCode: "de",
    label: "German",
  },
  {
    code: "nl",
    mapCode: "nl",
    label: "Dutch",
  },
];
