import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MenuItem,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers";
import { Calendar } from "react-feather";
import DatePicker from "react-multi-date-picker";
import styled from "styled-components/macro";

import { getIn, useField, useFormikContext } from "formik";

const TextField = styled(MuiTextField)(spacing);

//https://github.com/simpletut/React-Formik-Material-UI-Booking-Form-Template/blob/main/src/Components/FormsUI/

export const TextFieldWrapper = (props) => {
  const isTouched = getIn(props.form.touched, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);

  const { error, helperText, field, form, mask, options, ...rest } = props;

  return (
    <TextField
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={isTouched && errorMessage}
      {...rest} // includes any Material-UI specific props
      {...field} // includes all props contributed by the Formik Field/FastField
    ></TextField>
  );
};

export const SelectMultipleWrapper = (props) => {
  const isTouched = getIn(props.form.touched, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);

  const { disabled, error, helperText, field, form, options, value, ...rest } =
    props;
  const { name } = field;

  return (
    <Autocomplete
      {...field}
      onChange={(_, value) => form.setFieldValue(name, value)}
      onBlur={() => form.setTouched({ [name]: true })}
      multiple
      disabled={disabled}
      getOptionLabel={(option) => option.label}
      value={field.value}
      renderInput={(params) => (
        <TextField
          {...params}
          {...rest}
          error={error ?? Boolean(isTouched && errorMessage)}
          helperText={
            helperText ??
            (isTouched && errorMessage ? JSON.stringify(errorMessage) : "")
          }
        />
      )}
      renderOption={(params, option) => (
        <MenuItem key={option.value} {...params}>
          {option.label}
        </MenuItem>
      )}
      options={options}
    />
  );
};

export const SelectWrapper = (props) => {
  const errorMessage = getIn(props.form.errors, props.field.name);

  const {
    disabled,
    error,
    helperText,
    field,
    form,
    options,
    value,
    afterChange,
    ...rest
  } = props;
  const { name } = field;

  return (
    <Autocomplete
      {...field}
      onChange={(_, value) => {
        form.setFieldValue(name, value);
        if (afterChange) afterChange(value);
      }}
      onBlur={() => form.setTouched({ [name]: true })}
      getOptionLabel={(option) => option.label ?? " "}
      isOptionEqualToValue={(a, b) => a.value === b.value}
      disabled={disabled}
      value={field.value}
      renderInput={(params) => (
        <TextField
          {...params}
          {...rest}
          error={error ?? Boolean(errorMessage)}
          helperText={errorMessage}
          disabled={disabled}
        />
      )}
      renderOption={(params, option) => (
        <MenuItem key={option.value} {...params}>
          {option.label}
        </MenuItem>
      )}
      options={options}
    />
  );
};

export const SelectGroupWrapper = (props) => {
  const errorMessage = getIn(props.form.errors, props.field.name);

  const {
    disabled,
    error,
    helperText,
    field,
    form,
    options,
    value,
    afterChange,
    ...rest
  } = props;
  const { name } = field;

  return (
    <Autocomplete
      {...field}
      onChange={(_, value) => {
        form.setFieldValue(name, value);
        if (afterChange) afterChange(value);
      }}
      onBlur={() => form.setTouched({ [name]: true })}
      getOptionLabel={(option) => option.label ?? " "}
      groupBy={(option) => option.meta.tense}
      isOptionEqualToValue={(a, b) => a.value === b.value}
      disabled={disabled ?? false}
      value={field.value}
      renderInput={(params) => (
        <TextField
          {...params}
          {...rest}
          error={error ?? Boolean(errorMessage)}
          helperText={errorMessage}
        />
      )}
      renderOption={(params, option) => (
        <MenuItem key={option.value} {...params}>
          {option.label}
        </MenuItem>
      )}
      options={options.sort((a, b) => {
        if (a.meta.tenseOrder < b.meta.tenseOrder) {
          return -1;
        }
        if (a.meta.tenseOrder > b.meta.tenseOrder) {
          return 1;
        }
        // a must be equal to b
        return 0;
      })}
    />
  );
};

export const DateTimePickerWrapper = (props) => {
  const { error, helperText, field, form, ...rest } = props;
  const { name } = field;

  const isTouched = getIn(props.form.touched, name);
  const errorMessage = getIn(props.form.errors, name);

  return (
    <MuiDatePicker
      {...field}
      inputFormat="dd/MM/yyyy"
      value={field.value}
      openTo="day"
      onChange={(value) => {
        form.setFieldValue(name, value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...rest}
          value={params.value}
          error={error ?? Boolean(isTouched && errorMessage)}
          helperText={isTouched && errorMessage}
        />
      )}
    />
  );
};

export const DateTimePickerWrapperNotMUI = (props) => {
  const isTouched = getIn(props.form.touched, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);

  const { error, helperText, field, form, ...rest } = props;
  const { name } = field;

  return (
    <DatePicker
      {...field}
      className="rmdp-prime"
      onChange={(_, value) => form.setFieldValue(name, value)}
      value={field.value}
      format="DD/MM/YYYY"
      formattingIgnoreList={["Date", "Time"]}
      containerStyle={{ width: "100%" }}
      weekStartDayIndex={1}
      render={(value, openCalendar) => (
        <TextField
          error={error ?? Boolean(isTouched && errorMessage)}
          helperText={
            helperText ?? (isTouched && errorMessage ? errorMessage : " ")
          }
          onFocus={openCalendar}
          value={value}
          fullWidth={true}
          InputProps={{
            fullWidth: true,
            endAdornment: <Calendar onClick={openCalendar} />,
          }}
          sx={{ mt: 3 }}
          {...rest}
        />
      )}
    />
  );
};

export const CheckboxWrapper = ({
  name,
  label,
  afterChange = () => {},
  legend,
  disabled,
  ...otherProps
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (evt) => {
    const { checked } = evt.target;
    setFieldValue(name, checked);
    afterChange && afterChange();
  };

  const configCheckbox = {
    ...field,
    onChange: handleChange,
  };

  const configFormControl = {};
  if (meta && meta.touched && meta.error) {
    configFormControl.error = true;
  }

  return (
    <FormControl {...configFormControl}>
      <FormLabel component="legend">{legend ?? ""}</FormLabel>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              {...configCheckbox}
              checked={field.value}
              disabled={disabled}
            />
          }
          label={label}
        />
      </FormGroup>
    </FormControl>
  );
};

export const FileFieldWrapper = (props) => {
  const isTouched = getIn(props.form.touched, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);

  const { error, helperText, field, form, mask, accepts, ...rest } = props;

  return (
    <TextField
      fullWidth
      type="file"
      inputProps={{
        accepts: accepts,
      }}
      onChange={(event) => (field.value = event.target.files)}
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={
        helperText ?? (isTouched && errorMessage ? errorMessage : " ")
      }
      {...rest} // includes any Material-UI specific props
      {...field} // includes all props contributed by the Formik Field/FastField
    ></TextField>
  );
};
