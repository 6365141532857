import { GridCellEditCommitParams } from "@mui/x-data-grid";
import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { ActivityType, Customer, Dealer, Person } from "../../models/Core";
import { ISectionStatus } from "../../models/ISectionStatus";
import { StatusFlag } from "../../models/IStatusFlag";
import {
  ActivitySchedule,
  ActivitySchedulePriority,
} from "../../models/Scheduling";
import { IAutocompleteOption } from "../../models/UI";
import { CompareActivitySchedules } from "../../utils/DataGridHelpers";
import { isNullOrUndefined } from "../../utils/generalHelpers";
import {
  AsyncThunkFactoryGet,
  AsyncThunkFactoryPut,
} from "../../utils/NetworkUtils";
import { RootState } from "../store";

export interface ISchedulingSliceState {
  customers: Customer[];
  persons: Person[];
  dealers: Dealer[];
  activityTypes: ActivityType[];
  activitySchedules: ActivitySchedule[];
  personSchedules: ActivitySchedule[];
  sectionStatuses: {
    customers: ISectionStatus;
    persons: ISectionStatus;
    dealers: ISectionStatus;
    activityTypes: ISectionStatus;
    activitySchedules: ISectionStatus;
    personSchedules: ISectionStatus;
  };
}

const initialState = {
  customers: [],
  persons: [],
  dealers: [],
  activityTypes: [],
  activitySchedules: [],
  personSchedules: [],
  sectionStatuses: {
    customers: { status: "idle", error: undefined },
    persons: { status: "idle", error: undefined },
    dealers: { status: "idle", error: undefined },
    activityTypes: { status: "idle", error: undefined },
    activitySchedules: { status: "idle", error: undefined },
    personSchedules: { status: "idle", error: undefined },
  },
} as ISchedulingSliceState;

export const getPersonsForProject = AsyncThunkFactoryGet<Person[]>(
  "scheduling/getPersonsForProject",
  "projects"
);

export const getActivityTypes = AsyncThunkFactoryGet<ActivityType[]>(
  "scheduling/getActivityTypes",
  "activitytypes"
);

export const getCustomersForProject = AsyncThunkFactoryGet<Customer[]>(
  "scheduling/getCustomersForProject",
  "projects"
);

export const getActivitySchedules = AsyncThunkFactoryGet<ActivitySchedule[]>(
  "scheduling/getActivitySchedules",
  "scheduling/activityschedules"
);

export const getProjectPersonSchedule = AsyncThunkFactoryGet<
  ActivitySchedule[]
>("scheduling/getProjectPersonSchedule", "scheduling/activityschedules");

export const putActivitySchedules = AsyncThunkFactoryPut<ActivitySchedule[]>(
  "scheduling/putActivitySchedules",
  "scheduling/activityschedules",
  "Successfully updated activity schedules",
  "Failed to update activity schedules"
);

export const getDealers = AsyncThunkFactoryGet<Dealer[]>(
  "scheduling/getDealers",
  "scheduling/activityschedules"
);

export const getDealersByCustomerId = AsyncThunkFactoryGet<Dealer[]>(
  "scheduling/getDealersByCustomerId",
  "customers"
);

function ActionReducerBuilderGet<T>(
  builder: ActionReducerMapBuilder<ISchedulingSliceState>,
  f: any,
  field: keyof ISchedulingSliceState,
  statusField: keyof ISchedulingSliceState["sectionStatuses"],
  thenF?: (state: ISchedulingSliceState, action: any) => any,
  manipulateResult?: (result: T) => any
) {
  builder
    .addCase(f.pending, (state) => {
      state.sectionStatuses[statusField].status = "loading";
    })
    .addCase(f.fulfilled, (state, action) => {
      state.sectionStatuses[statusField].status = "succeeded";
      state[field] =
        manipulateResult !== undefined
          ? manipulateResult(action.payload.result)
          : action.payload.result;

      if (thenF) thenF(state, action);
    })
    .addCase(f.rejected, (state, action) => {
      state.sectionStatuses[statusField].status = "error";
      state.sectionStatuses[statusField].error = action.error.message;
    });
}

// function ActionReducerBuilderPost<T>(
//   builder: ActionReducerMapBuilder<ISchedulingSliceState>,
//   f: any,
//   field: keyof ISchedulingSliceState,
//   statusField: keyof ISchedulingSliceState["sectionStatuses"],
//   shouldAppendResult: boolean,
//   manipulateResult?: (result: T) => any
// ) {
//   builder
//     .addCase(f.pending, (state) => {
//       state.sectionStatuses[statusField].status = "loading";
//     })
//     .addCase(f.fulfilled, (state, action) => {
//       state.sectionStatuses[statusField].status = "succeeded";
//       if (
//         action.payload.status === 201 &&
//         action.payload.result &&
//         shouldAppendResult
//       ) {
//         var manipulated =
//           manipulateResult !== undefined
//             ? manipulateResult(action.payload.result)
//             : action.payload.result;

//         if (Array.isArray(state[field])) {
//           (state[field] as any) = (state[field] as any).concat(manipulated);
//         }
//       }
//     })
//     .addCase(f.rejected, (state, action) => {
//       state.sectionStatuses[statusField].status = "error";
//       state.sectionStatuses[statusField].error = action.error.message;
//     });
// }

// function ActionReducerBuilderDelete<T>(
//   builder: ActionReducerMapBuilder<ISchedulingSliceState>,
//   f: any,
//   field: keyof ISchedulingSliceState,
//   statusField: keyof ISchedulingSliceState["sectionStatuses"],
//   predicate: (element: T) => boolean
// ) {
//   builder
//     .addCase(f.pending, (state) => {
//       state.sectionStatuses[statusField].status = "loading";
//     })
//     .addCase(f.fulfilled, (state, action) => {
//       state.sectionStatuses[statusField].status = "succeeded";
//       if (action.payload.status === 204 && Array.isArray(state[field])) {
//         (state[field] as any[]) = (state[field] as any[]).filter(
//           predicate,
//           action.meta.arg
//         );
//       }
//     })
//     .addCase(f.rejected, (state, action) => {
//       state.sectionStatuses[statusField].status = "error";
//       state.sectionStatuses[statusField].error = action.error.message;
//     });
// }

function ActionReducerBuilderPut(
  builder: ActionReducerMapBuilder<ISchedulingSliceState>,
  f: any,
  field: keyof ISchedulingSliceState,
  statusField: keyof ISchedulingSliceState["sectionStatuses"]
) {
  builder
    .addCase(f.pending, (state) => {
      state.sectionStatuses[statusField].status = "loading";
    })
    .addCase(f.fulfilled, (state, action) => {
      state.sectionStatuses[statusField].status = "succeeded";
    })
    .addCase(f.rejected, (state, action) => {
      state.sectionStatuses[statusField].status = "error";
      state.sectionStatuses[statusField].error = action.error.message;
    });
}

export const schedulingSlice = createSlice({
  name: "scheduling",
  initialState,
  reducers: {
    addActivitySchedule: (state, action: { payload: string }) => {
      var findLowest = Math.min(...state.activitySchedules.map((x) => x.id));
      state.activitySchedules = [
        {
          id: findLowest > 0 ? -1 : findLowest - 1,
          activityTypeId: 0,
          customerId: 0,
          dealerId: 0,
          personId: 0,
          projectId: action.payload,
          year: new Date().getFullYear(),
          month: 1,
          days: 0,
          priority: ActivitySchedulePriority.Normal,
          statusFlag: StatusFlag.New,
        },
        ...state.activitySchedules,
      ];
    },
    editActivitySchedule: (
      state,
      action: {
        payload: {
          params: GridCellEditCommitParams;
          externalItems: {
            dealers: IAutocompleteOption[];
            activityTypes: ISchedulingActivityTypeOption[];
            customers: IAutocompleteOption[];
          };
          originalItem: ActivitySchedule | undefined;
        };
      }
    ) => {
      const { params, externalItems, originalItem } = action.payload;
      var row = state.activitySchedules.find((x) => x.id === params.id);
      if (row === undefined) return;
      switch (params.field) {
        case "customerId":
          if (row !== undefined) {
            if (row.customerId !== Number(params.value)) {
              row.dealerId = undefined;
              row.dealerName = "";
            }
            row.customerId = Number(params.value);
            row.customerName =
              externalItems.customers.find(
                (x) => x.value === Number(params.value)
              )?.label ?? "";
          }
          break;
        case "dealerId":
          if (row !== undefined) {
            row.dealerId = Number(params.value);
            row.dealerName =
              externalItems.dealers.find(
                (x) => x.value === Number(params.value)
              )?.label ?? "";
          }
          break;
        case "personId":
          if (row !== undefined) row.personId = Number(params.value);
          break;
        case "activityTypeId":
          if (row !== undefined) {
            if (
              !externalItems.activityTypes.find(
                (x) => x.value === Number(params.value)
              )?.meta.requiresDealer
            ) {
              row.dealerId = undefined;
              row.dealerName = "";
            }
            row.activityTypeId = Number(params.value);
          }
          break;
        case "priority":
          if (row !== undefined) row.priority = Number(params.value);
          break;
        case "month":
          if (row !== undefined) row.month = Number(params.value);
          break;
        case "year":
          if (row !== undefined) row.year = Number(params.value);
          break;
        case "days":
          if (row !== undefined) row.days = Number(params.value);
          break;
        default:
          return;
      }

      if (row !== undefined && !isNullOrUndefined(originalItem)) {
        if (CompareActivitySchedules(row, originalItem))
          row.statusFlag = StatusFlag.Existing;
        else row.statusFlag = StatusFlag.Modified;
      }

      state.activitySchedules = state.activitySchedules.reduce(
        (acc: ActivitySchedule[], curr) => {
          if (curr.id !== params.id) acc.push(curr);
          else acc.push({ ...curr, ...row });
          return acc;
        },
        []
      );
    },
    markActivityScheduleForDelete: (state, action: { payload: number }) => {
      state.activitySchedules = state.activitySchedules.reduce(
        (acc: ActivitySchedule[], curr) => {
          if (curr.id !== action.payload) acc.push(curr);
          else if (action.payload > 0)
            acc.push({ ...curr, statusFlag: StatusFlag.ToRemove });
          return acc;
        },
        []
      );
    },
    markActivityScheduleForKeep: (
      state,
      action: { payload: { id: number; current: ActivitySchedule | undefined } }
    ) => {
      const { id, current } = action.payload;
      state.activitySchedules = state.activitySchedules.reduce(
        (acc: ActivitySchedule[], curr) => {
          if (curr.id !== id) acc.push(curr);
          else
            acc.push({
              ...curr,
              statusFlag: CompareActivitySchedules(curr, current)
                ? StatusFlag.Existing
                : StatusFlag.Modified,
            });

          return acc;
        },
        []
      );
    },
    duplicateActivitySchedule: (state, action: { payload: number }) => {
      var findLowest = Math.min(...state.activitySchedules.map((x) => x.id));
      var duplicatee = state.activitySchedules.find(
        (x) => x.id === action.payload
      );
      if (duplicatee === undefined) return;

      state.activitySchedules = state.activitySchedules.reduce(
        (acc: ActivitySchedule[], curr) => {
          if (curr.id !== action.payload) acc.push(curr);
          else {
            acc.push({
              ...curr,
              statusFlag: StatusFlag.New,
              id: findLowest > 0 ? -1 : findLowest - 1,
            });
            acc.push(curr);
          }
          return acc;
        },
        []
      );
    },
    resetStatus: (state, action) => {
      switch (action.payload) {
        default:
          return;
      }
    },
    clearDealers: (state) => {
      state.dealers = [];
    },
    clearPersons: (state) => {
      state.persons = [];
    },
    clearPersonSchedules: (state) => {
      state.personSchedules = [];
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    ActionReducerBuilderGet(
      builder,
      getActivitySchedules,
      "activitySchedules",
      "activitySchedules"
    );

    ActionReducerBuilderGet(builder, getDealers, "dealers", "dealers");

    ActionReducerBuilderGet(
      builder,
      getDealersByCustomerId,
      "dealers",
      "dealers"
    );

    ActionReducerBuilderGet(
      builder,
      getCustomersForProject,
      "customers",
      "customers"
    );

    ActionReducerBuilderGet(
      builder,
      getPersonsForProject,
      "persons",
      "persons"
    );

    ActionReducerBuilderPut(
      builder,
      putActivitySchedules,
      "activitySchedules",
      "activitySchedules"
    );

    ActionReducerBuilderGet(
      builder,
      getProjectPersonSchedule,
      "personSchedules",
      "personSchedules"
    );

    ActionReducerBuilderGet(
      builder,
      getActivityTypes,
      "activityTypes",
      "activityTypes"
    );
  },
});

export const schedulingDealersAsDict = (
  state: RootState
): IAutocompleteOption[] =>
  state.scheduling.dealers
    .map((x) => ({
      value: x.id,
      label: `${x.code}: ${x.name}`,
    }))
    .sort((a, b) => a?.label?.localeCompare(b?.label));

export const schedulingCustomersAsDict = (
  state: RootState
): IAutocompleteOption[] =>
  state.scheduling.customers
    .map((x) => ({
      value: x.id,
      label: x.name,
    }))
    .sort((a, b) => a?.label?.localeCompare(b?.label));

export const schedulingPersonsAsDict = (
  state: RootState
): IAutocompleteOption[] =>
  state.scheduling.persons
    .map((x) => ({
      value: x.personId,
      label: x.name,
    }))
    .sort((a, b) => a?.label?.localeCompare(b?.label));

interface ISchedulingActivityTypeOption extends IAutocompleteOption {
  meta: {
    requiresDealer: boolean;
  };
}

export const schedulingActivityTypesAsDict = (
  state: RootState
): ISchedulingActivityTypeOption[] =>
  state.scheduling.activityTypes
    .map((x) => ({
      value: x.id,
      label: x.name,
      meta: {
        requiresDealer: x.requiresDealer,
      },
    }))
    .sort((a, b) => a?.label?.localeCompare(b?.label));

export const {
  addActivitySchedule,
  editActivitySchedule,
  markActivityScheduleForDelete,
  markActivityScheduleForKeep,
  duplicateActivitySchedule,
  resetStatus,
  clearDealers,
  clearPersons,
  clearPersonSchedules,
} = schedulingSlice.actions;

export default schedulingSlice.reducer;
