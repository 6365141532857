import styled from "styled-components/macro";
import { Avatar, Typography, Skeleton, Box } from "@mui/material";
import { stringToColor } from "../Avatars";
import { MSGraphPersonContext } from "../../contexts/MSGraphPeopleContext";
import { useContext, useMemo } from "react";
import { useMsal } from "@azure/msal-react";
import {
  isNullOrUndefined,
  initialsFromString,
} from "../../utils/generalHelpers";

const Footer = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const FooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const FooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
`;

function GetText({ personName }) {
  const { people } = useContext(MSGraphPersonContext);
  const person = people.find((x) => x?.displayName === personName);
  return (
    <Box sx={{ width: "100%" }}>
      {person?.displayName === undefined ? (
        <Skeleton
          animation="wave"
          width="65%"
          children={<Typography width={"65%"}>.</Typography>}
        />
      ) : (
        <FooterText variant="body2">{person?.displayName}</FooterText>
      )}
      {person?.jobTitle === undefined ? (
        <Skeleton
          animation="wave"
          width="100%"
          children={<Typography width={"100%"}>.</Typography>}
        />
      ) : (
        <FooterSubText variant="caption">{person?.jobTitle}</FooterSubText>
      )}
    </Box>
  );
}

function GetImage({ personName }) {
  const { people } = useContext(MSGraphPersonContext);
  const person = people.find((x) => x?.displayName === personName);

  return (
    <>
      {!isNullOrUndefined(person?.photo) ? (
        <Avatar alt={`${person?.displayName} avatar`} src={person?.photo} />
      ) : !isNullOrUndefined(person?.displayName) ? (
        <Avatar
          sx={{ bgcolor: stringToColor(person?.displayName) }}
          children={initialsFromString(person?.displayName)}
        />
      ) : (
        <Skeleton
          animation="wave"
          variant="circular"
          width="100%"
          height="100%"
          children={<Avatar />}
        />
      )}
    </>
  );
}

const SidebarFooter = ({ ...rest }) => {
  const { accounts } = useMsal();

  const name = useMemo(() => accounts[0]?.name, [accounts]);

  return (
    <Footer {...rest}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ margin: 1 }}>
          <GetImage personName={name} />
        </Box>
        <Box sx={{ width: "100%", pl: 2 }}>
          <GetText personName={name} />
        </Box>
      </Box>
    </Footer>
  );
};

export default SidebarFooter;
