import styled from "styled-components/macro";
import { RoleGuard } from "../Guards";
import { Typography } from "@mui/material";
import SidebarNavList from "./SidebarNavList";
import { useTranslation } from "react-i18next";

const Title = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  font-size: ${(props) => props.theme.typography.caption.fontSize};
  padding: ${(props) => props.theme.spacing(4)}
    ${(props) => props.theme.spacing(7)} ${(props) => props.theme.spacing(1)};
  opacity: 0.4;
  text-transform: uppercase;
  display: block;
`;

const SidebarNavSection = (props) => {
  const {
    roles,
    matchType,
    title,
    pages,
    className,
    component: Component = "nav",
    ...rest
  } = props;
  const { t } = useTranslation();

  return (
    <RoleGuard roles={roles} matchType={matchType}>
      <Component {...rest}>
        {title && <Title variant="subtitle2">{t(title)}</Title>}
        <SidebarNavList pages={pages} depth={0} />
      </Component>
    </RoleGuard>
  );
};

export default SidebarNavSection;
