export function getFromLS(key: string) {
  let ls = {} as any;
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem(`${key}`) ?? "");
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key] as any;
}

export function saveToLS(key: string, value: any) {
  if (global.localStorage) {
    global.localStorage.setItem(
      `${key}`,
      JSON.stringify({
        [key]: value,
      })
    );
  }
}
