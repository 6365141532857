import { ActivityType, Country, Customer, Person, Team } from "./Core";
import { StatusFlag } from "./IStatusFlag";
import { Project } from "./Project";

export interface Quotation {
  id: string;
  projectId: string;
  project?: Project;
  name: string;
  note: string;
  quotationStatus: QuotationStatus;
  currencyId: number;
  currency?: Currency;
  profitMargin: number;
  exchangeRate: number;
  lastModifiedDate: Date;
  lastModifiedBy: number;
  lastModifiedPerson?: Person;
  quotationItems: QuotationItem[];
  quotationItemAddOns: QuotationItemAddOn[];
}

export interface QuotationItem {
  id: number;
  quotationId: string;
  activityTypeId: number;
  activityType?: ActivityType;
  duration: number;
  nonProductiveDuration: number;
  quantity: number;
  customerId: number;
  customer?: Customer;
  comment: string;
  teamItemPrice: number;
  teamItemPriceOverride: number;
  useTeamItemPriceOverride: boolean;
  teamPricingGroupId?: number;
  teamPricingGroup?: TeamPricingGroup;
  countryItemPrice: number;
  countryItemPriceOverride: number;
  useCountryItemPriceOverride: boolean;
  countryPricingGroupId?: number;
  countryPricingGroup?: CountryPricingGroup;
  estimatedExpenses: number;
  includeExpensesInTotal: boolean;
  requiresDealerSelection: boolean;
  requiresPlanning: boolean;
  showOnInvoice: boolean;
  showOnQuotation: boolean;
  adjustment: number;
  statusFlag: StatusFlag;
}

export interface QuotationItemAddOn {
  id: number;
  quotationId: string;
  title: string;
  quantity: number;
  customerId: number;
  customer?: Customer;
  cost: number;
  adjustment: number;
  comment: string;
  billableMonth?: number | null;
  billableYear: number;
  statusFlag: StatusFlag;
}

export enum QuotationStatus {
  New = 0,
  Pending = 1,
  Final = 2,
}

export interface TeamPricingGroup {
  id: number;
  name: string;
  fullName?: string;
  personId?: number | null;
  person?: Person;
  teamId?: number | null;
  team?: Team;
  price: number;
  date: Date;
}

export interface CountryPricingGroup {
  id: number;
  name: string;
  fullName?: string;
  countryId?: number | null;
  country?: Country;
  price: number;
  date: Date;
}

export interface Currency {
  id: number;
  name: string;
  symbol: string;
}

export const InvoiceFrequency = [
  {
    id: 1,
    label: "Weekly",
  },
  {
    id: 2,
    label: "Monthly",
  },
  {
    id: 3,
    label: "Quarterly",
  },
  {
    id: 4,
    label: "Yearly",
  },
];

export interface InvoiceSchedule {
  id: number;
  projectId: string;
  project?: Project;
  customerId: number;
  customer?: Customer;
  name: string;
  initialDate?: Date | null;
  frequency: number;
  attachmentItems?: InvoiceScheduleAttachmentItem[];
  attachmentItemsCount: number;
  scheduleEntries?: InvoiceScheduleItem[];
  schedulesEntriesCount: number;
  lastModifiedById?: number;
  lastModifiedBy?: Person;
}

export interface InvoiceScheduleItem {
  id: number;
  invoiceScheduleId: number;
  invoiceSchedule: InvoiceSchedule;
  date: Date;
  dateSent?: Date;
  datePaid?: Date;
  lastModifiedById?: number;
  lastModifiedBy: Person;
  statusFlag?: StatusFlag;
}

export interface InvoiceScheduleAttachmentItem {
  id: number;
  invoiceScheduleId: number;
  invoiceSchedule?: InvoiceSchedule;
  activityTypeId: number;
  activityType?: ActivityType;
  isBillable: boolean;
  statusFlag?: StatusFlag;
}

export interface InvoiceScheduleItemViewModel {
  invoiceScheduleItem: InvoiceScheduleItem;
  companyName: string;
  companyId: number;
  name: string;
  projectCode: string;
  projectTitle: string;
  customer: string;
  billableEvents: number;
  nonBillableEvents: number;
}

export interface Expense {
  expenseId: number;
  reportEntryExpenseTypeName: string;
  reportEntryTransactionDate: Date;
  reportEntryTransactionAmount: number;
  reportEntryTaxReclaimTransactionAmount: number;
  journalNetofTotalAdjustedReclaimTax: number;
}

export interface ExpenseTypeCategory {
  expenseTypeCategoryId: number;
  name: string;
}

export interface ExpenseType {
  expenseTypeId: number;
  name: string;
  categoryId: number;
  category: ExpenseTypeCategory;
}

export interface ExpenseEditorViewModel {
  expenseId: number;
  eventId?: number;
  customerName: string;
  person: string;
  currency: number;
  expenseTypeId?: number;
  localAmount: number;
  grossAmount: number;
  vatAmount: number;
  netAmount: number;
  purchaseDate: string;
  batchDate: string;
  vatRate: number;
  statusFlag: StatusFlag;
}

export interface ExpenseExchangeRate {
  expenseExchangeRateId: number;
  currencyFromId: number;
  currencyFrom?: Currency;
  currencyToId: number;
  currencyTo?: Currency;
  rate: number;
  month: Date;
  statusFlag?: StatusFlag;
}

export enum PurchaseOrderStatus {
  Requested = 0,
  Declined = 1,
  Accepted = 2,
}

export interface PurchaseOrder {
  purchaseOrderId: number;
  title: string;
  description: string;
  currencyId: number;
  currency: Currency;
  value: number;
  status: PurchaseOrderStatus;
  requestDate: Date;
  requestingPersonId: number;
  requestingPerson: Person;
  authorizingPersonId: number;
  authorizingPerson: Person;
  responseDate: Date;
}

export interface OdometerReading {
  id: number;
  vehicleId: number;
  registrationNumber: string;
  reading: number;
  unitType: number;
  personId: number;
  person?: Person;
  comment: string;
  submissionDate: Date;
}

export enum OdometerType {
  KM = 1,
  ML = 2,
}
