import React from "react";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import {
  AppBar as MuiAppBar,
  Typography as MuiTypography,
  Container,
  Toolbar,
  Box,
} from "@mui/material";
import Introduction from "./Introduction";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

const Typography = styled(MuiTypography)(spacing);

function BottomBar() {
  return (
    <MuiAppBar
      position="fixed"
      color="primary"
      sx={(theme) => ({
        top: "auto",
        bottom: 0,
      })}
    >
      <Toolbar>
        <Container>
          <Box sx={{ flexDirection: "row-reverse" }}>
            <Typography sx={{ textAlign: "right" }}>
              ECS Auto v{process.env.REACT_APP_VERSION} © 2023 The ECS Group
            </Typography>
          </Box>
        </Container>
      </Toolbar>
    </MuiAppBar>
  );
}

export function index() {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        backgroundImage: `url(${process.env.PUBLIC_URL}/static/img/unsplash/unsplash-1.jpg)`,
        backgroundSize: "cover",
      }}
    >
      <Root>
        <Introduction />
      </Root>
      <BottomBar />
    </Box>
  );
}

export default index;
