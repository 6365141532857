import { GridCellEditCommitParams } from "@mui/x-data-grid";
import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import {
  InvoiceSchedule,
  InvoiceScheduleAttachmentItem,
  InvoiceScheduleItem,
  InvoiceScheduleItemViewModel,
} from "../../models/Finance";
import { ISectionStatus } from "../../models/ISectionStatus";
import { StatusFlag } from "../../models/IStatusFlag";
import {
  CompareInvoiceScheduleAttachmentItems,
  CompareInvoiceScheduleItems,
} from "../../utils/DataGridHelpers";
import { isNullOrUndefined } from "../../utils/generalHelpers";
import {
  AsyncThunkFactoryGet,
  AsyncThunkFactoryPost,
  AsyncThunkFactoryPut,
} from "../../utils/NetworkUtils";

export interface IInvoicingSliceState {
  invoiceSchedules: InvoiceSchedule[];
  invoiceSchedule: InvoiceSchedule;
  globalInvoiceScheduleItems: InvoiceScheduleItemViewModel[];
  sectionStatuses: {
    invoiceSchedules: ISectionStatus;
    invoiceSchedule: ISectionStatus;
  };
}

const initialState: IInvoicingSliceState = {
  invoiceSchedules: [],
  globalInvoiceScheduleItems: [],
  invoiceSchedule: {
    id: 0,
    projectId: "",
    customerId: 0,
    name: "",
    frequency: 0,
    attachmentItems: [],
    attachmentItemsCount: 0,
    scheduleEntries: [],
    schedulesEntriesCount: 0,
  },
  sectionStatuses: {
    invoiceSchedules: { status: "idle", error: undefined },
    invoiceSchedule: { status: "idle", error: undefined },
  },
};

export const getProjectInvoiceSchedules = AsyncThunkFactoryGet<
  InvoiceSchedule[]
>("finance/getProjectInvoiceSchedules", "finance/invoiceschedules");

export const getInvoiceSchedule = AsyncThunkFactoryGet<InvoiceSchedule>(
  "finance/getInvoiceSchedule",
  "finance/InvoiceSchedules"
);

export const getGlobalInvoiceScheduleItems = AsyncThunkFactoryGet<
  InvoiceScheduleItemViewModel[]
>("finance/getGlobalInvoiceScheduleItems", "finance/invoicescheduleitems");

export const putManyInvoiceScheduleItems = AsyncThunkFactoryPut(
  "finance/putManyInvoiceSchedules",
  "finance/invoicescheduleitems",
  "Successfully updated invoice schedule items",
  "Failed to update invoice schedule items"
);

export const postInvoiceSchedule = AsyncThunkFactoryPost(
  "finance/postInvoiceSchedule",
  "finance/invoiceschedules",
  "Successfully added invoice schedule",
  "Failed to add customer"
);

export const putInvoiceSchedule = AsyncThunkFactoryPut(
  "finance/putInvoiceSchedule",
  "finance/invoiceschedules",
  "Successfully updated invoice schedule",
  "Failed to update invoice schedule"
);

function ActionReducerBuilderGet<T>(
  builder: ActionReducerMapBuilder<IInvoicingSliceState>,
  f: any,
  field: keyof IInvoicingSliceState,
  statusField: keyof IInvoicingSliceState["sectionStatuses"],
  manipulateResult?: (result: T) => any
) {
  builder
    .addCase(f.pending, (state) => {
      state.sectionStatuses[statusField].status = "loading";
    })
    .addCase(f.fulfilled, (state, action) => {
      state.sectionStatuses[statusField].status = "succeeded";
      state[field] =
        manipulateResult !== undefined
          ? manipulateResult(action.payload.result)
          : action.payload.result;
    })
    .addCase(f.rejected, (state, action) => {
      state.sectionStatuses[statusField].status = "error";
      state.sectionStatuses[statusField].error = action.error.message;
    });
}

function ActionReducerBuilderPost<T>(
  builder: ActionReducerMapBuilder<IInvoicingSliceState>,
  f: any,
  field: keyof IInvoicingSliceState,
  statusField: keyof IInvoicingSliceState["sectionStatuses"],
  shouldAppendResult: boolean,
  manipulateResult?: (result: T) => any
) {
  builder
    .addCase(f.pending, (state) => {
      state.sectionStatuses[statusField].status = "loading";
    })
    .addCase(f.fulfilled, (state, action) => {
      state.sectionStatuses[statusField].status = "succeeded";
      if (
        action.payload.status === 201 &&
        action.payload.result &&
        shouldAppendResult
      ) {
        var manipulated =
          manipulateResult !== undefined
            ? manipulateResult(action.payload.result)
            : action.payload.result;

        if (Array.isArray(state[field])) {
          (state[field] as any) = (state[field] as any).concat(manipulated);
        }
      }
    })
    .addCase(f.rejected, (state, action) => {
      state.sectionStatuses[statusField].status = "error";
      state.sectionStatuses[statusField].error = action.error.message;
    });
}

// function ActionReducerBuilderDelete<T>(
//   builder: ActionReducerMapBuilder<IInvoicingSliceState>,
//   f: any,
//   field: keyof IInvoicingSliceState,
//   statusField: keyof IInvoicingSliceState["sectionStatuses"],
//   predicate: (element: T) => boolean
// ) {
//   builder
//     .addCase(f.pending, (state) => {
//       state.sectionStatuses[statusField].status = "loading";
//     })
//     .addCase(f.fulfilled, (state, action) => {
//       state.sectionStatuses[statusField].status = "succeeded";
//       if (action.payload.status === 204) {
//         (state[field] as any[]) = (state[field] as any[]).filter(
//           predicate,
//           action.meta.arg
//         );
//       }
//     })
//     .addCase(f.rejected, (state, action) => {
//       state.sectionStatuses[statusField].status = "error";
//       state.sectionStatuses[statusField].error = action.error.message;
//     });
// }

function ActionReducerBuilderPut(
  builder: ActionReducerMapBuilder<IInvoicingSliceState>,
  f: any,
  field: keyof IInvoicingSliceState,
  statusField: keyof IInvoicingSliceState["sectionStatuses"]
) {
  builder
    .addCase(f.pending, (state) => {
      state.sectionStatuses[statusField].status = "loading";
    })
    .addCase(f.fulfilled, (state, action) => {
      state.sectionStatuses[statusField].status = "succeeded";
    })
    .addCase(f.rejected, (state, action) => {
      state.sectionStatuses[statusField].status = "error";
      state.sectionStatuses[statusField].error = action.error.message;
    });
}

export const invoiceSlice = createSlice({
  name: "Invoicing",
  initialState,
  reducers: {
    addInvoiceScheduleAttachmentItem: (state) => {
      var findLowest = Math.min(
        ...(state.invoiceSchedule?.attachmentItems ?? []).map((x) => x.id)
      );
      state.invoiceSchedule!.attachmentItems = [
        {
          id: findLowest > 0 ? -1 : findLowest - 1,
          activityTypeId: 0,
          invoiceScheduleId: state.invoiceSchedule?.id ?? 0,
          isBillable: false,
          statusFlag: StatusFlag.New,
        },
        ...(state.invoiceSchedule?.attachmentItems ?? []),
      ];
    },
    editInvoiceScheduleAttachmentItem: (
      state,
      action: {
        payload: {
          params: GridCellEditCommitParams;
          original: InvoiceScheduleAttachmentItem | undefined;
        };
      }
    ) => {
      const { params, original } = action.payload;
      var row = state.invoiceSchedule?.attachmentItems?.find(
        (x) => x.id === params.id
      );
      if (row === undefined) return;
      switch (params.field) {
        case "activityTypeId":
          row.activityTypeId = Number(params.value);
          break;
        case "isBillable":
          row.isBillable = Boolean(params.value);
          break;
      }

      if (row !== undefined && !isNullOrUndefined(original)) {
        if (CompareInvoiceScheduleAttachmentItems(row, original))
          row.statusFlag = StatusFlag.Existing;
        else row.statusFlag = StatusFlag.Modified;
      }

      state.invoiceSchedule!.attachmentItems =
        state.invoiceSchedule!.attachmentItems?.reduce(
          (acc: InvoiceScheduleAttachmentItem[], curr) => {
            if (curr.id !== params.id) acc.push(curr);
            else acc.push({ ...curr, ...row });
            return acc;
          },
          []
        );
    },
    markInvoiceScheduleAttachmentItemForDelete: (
      state,
      action: { payload: number }
    ) => {
      state.invoiceSchedule!.attachmentItems =
        state.invoiceSchedule!.attachmentItems?.reduce(
          (acc: InvoiceScheduleAttachmentItem[], curr) => {
            if (curr.id !== action.payload) acc.push(curr);
            else if (action.payload > 0)
              acc.push({ ...curr, statusFlag: StatusFlag.ToRemove });
            return acc;
          },
          []
        );
    },
    markInvoiceScheduleAttachmentItemForKeep: (
      state,
      action: {
        payload: {
          id: number;
          current: InvoiceScheduleAttachmentItem | undefined;
        };
      }
    ) => {
      const { id, current } = action.payload;
      state.invoiceSchedule!.attachmentItems =
        state.invoiceSchedule!.attachmentItems?.reduce(
          (acc: InvoiceScheduleAttachmentItem[], curr) => {
            if (curr.id !== id) acc.push(curr);
            else
              acc.push({
                ...curr,
                statusFlag: CompareInvoiceScheduleAttachmentItems(curr, current)
                  ? StatusFlag.Existing
                  : StatusFlag.Modified,
              });
            return acc;
          },
          []
        );
    },
    editInvoiceScheduleItem: (
      state,
      action: {
        payload: {
          params: GridCellEditCommitParams;
          original: InvoiceScheduleItem | undefined;
        };
      }
    ) => {
      const { params, original } = action.payload;
      var row = state.invoiceSchedule?.scheduleEntries?.find(
        (x) => x.id === params.id
      );
      if (row === undefined) return;
      switch (params.field) {
        case "date":
          if (row !== undefined) row.date = params.value as Date;
          break;
        case "dateSent":
          if (row !== undefined) row.dateSent = params.value as Date;
          break;
        case "datePaid":
          if (row !== undefined) row.datePaid = params.value as Date;
          break;
      }

      if (row !== undefined && !isNullOrUndefined(original)) {
        if (CompareInvoiceScheduleItems(row, original))
          row.statusFlag = StatusFlag.Existing;
        else row.statusFlag = StatusFlag.Modified;
      }

      state.invoiceSchedule!.scheduleEntries =
        state.invoiceSchedule!.scheduleEntries?.reduce(
          (acc: InvoiceScheduleItem[], curr) => {
            if (curr.id !== params.id) acc.push(curr);
            else acc.push({ ...curr, ...row });
            return acc;
          },
          []
        );
    },
    markInvoiceScheduleItemForDelete: (state, action: { payload: number }) => {
      state.invoiceSchedule!.scheduleEntries =
        state.invoiceSchedule!.scheduleEntries?.reduce(
          (acc: InvoiceScheduleItem[], curr) => {
            if (curr.id !== action.payload) acc.push(curr);
            else if (action.payload > 0)
              acc.push({ ...curr, statusFlag: StatusFlag.ToRemove });
            return acc;
          },
          []
        );
    },
    markInvoiceScheduleItemForKeep: (
      state,
      action: {
        payload: {
          id: number;
          current: InvoiceScheduleItem | undefined;
        };
      }
    ) => {
      const { id, current } = action.payload;
      state.invoiceSchedule!.scheduleEntries =
        state.invoiceSchedule!.scheduleEntries?.reduce(
          (acc: InvoiceScheduleItem[], curr) => {
            if (curr.id !== id) acc.push(curr);
            else
              acc.push({
                ...curr,
                statusFlag: CompareInvoiceScheduleItems(curr, current)
                  ? StatusFlag.Existing
                  : StatusFlag.Modified,
              });
            return acc;
          },
          []
        );
    },
    setInvoiceSchedule: (
      state,
      action: { payload: Partial<InvoiceSchedule> }
    ) => {
      state.invoiceSchedule = {
        id: 0,
        projectId: "",
        customerId: 0,
        name: "",
        frequency: 0,
        attachmentItems: [],
        attachmentItemsCount: 0,
        scheduleEntries: [],
        schedulesEntriesCount: 0,
        ...action.payload,
      };
    },
    resetStatus: (state, action) => {
      switch (action.payload) {
        case "invoiceSchedules":
          state.sectionStatuses.invoiceSchedules.status = "idle";
          break;
        case "invoiceSchedule":
          state.sectionStatuses.invoiceSchedule.status = "idle";
          break;
      }
    },
    resetInvoicing: (state) => {
      state.sectionStatuses.invoiceSchedules.status = "idle";
      state.sectionStatuses.invoiceSchedule.status = "idle";
    },
  },
  extraReducers: (builder) => {
    ActionReducerBuilderGet(
      builder,
      getProjectInvoiceSchedules,
      "invoiceSchedules",
      "invoiceSchedules"
    );

    ActionReducerBuilderGet(
      builder,
      getInvoiceSchedule,
      "invoiceSchedule",
      "invoiceSchedule"
    );

    ActionReducerBuilderPost(
      builder,
      postInvoiceSchedule,
      "invoiceSchedule",
      "invoiceSchedule",
      false
    );

    ActionReducerBuilderPut(
      builder,
      putInvoiceSchedule,
      "invoiceSchedule",
      "invoiceSchedule"
    );

    ActionReducerBuilderGet(
      builder,
      getGlobalInvoiceScheduleItems,
      "globalInvoiceScheduleItems",
      "invoiceSchedules"
    );

    ActionReducerBuilderPut(
      builder,
      putManyInvoiceScheduleItems,
      "invoiceSchedule",
      "invoiceSchedule"
    );
  },
});

export const {
  addInvoiceScheduleAttachmentItem,
  editInvoiceScheduleAttachmentItem,
  markInvoiceScheduleAttachmentItemForDelete,
  markInvoiceScheduleAttachmentItemForKeep,
  editInvoiceScheduleItem,
  markInvoiceScheduleItemForDelete,
  markInvoiceScheduleItemForKeep,
  setInvoiceSchedule,
  resetStatus,
  resetInvoicing,
} = invoiceSlice.actions;

export default invoiceSlice.reducer;
