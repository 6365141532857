import merge from "deepmerge";
import { green, grey, red } from "@mui/material/colors";
import { THEMES } from "../constants";

const customBlue = {
  50: "#e9f0fb",
  100: "#c8daf4",
  200: "#a3c1ed",
  300: "#7ea8e5",
  400: "#6395e0",
  500: "#4782da",
  600: "#407ad6",
  700: "#376fd0",
  800: "#2f65cb",
  900: "#2052c2 ",
};

const customBlue2 = {
  50: "#e5ecf1",
  100: "#bfcfdc",
  200: "#94afc4",
  300: "#698eac",
  400: "#49769b",
  500: "#295e89",
  600: "#245681",
  700: "#1f4c76",
  800: "#19426c",
  900: "#0f3159",
};

const customRed = {
  50: "#FCEBEB",
  100: "#F8CECD",
  200: "#F4ADAC",
  300: "#F08C8B",
  400: "#EC7372",
  500: "#E95A59",
  600: "#E65251",
  700: "#E34848",
  800: "#DF3F3E",
  900: "#D92E2E ",
};

const defaultVariant = {
  name: THEMES.DEFAULT,
  palette: {
    mode: "light",
    primary: {
      main: customBlue[700],
      contrastText: "#FFF",
    },
    secondary: {
      main: customBlue[500],
      contrastText: "#FFF",
    },
    background: {
      default: "#F7F9FC",
      paper: "#FFF",
    },
    fab: { color: grey[200], background: "#233044" },
  },
  gridItem: {
    background: grey[200],
    color: "black",
  },
  header: {
    color: grey[500],
    background: "#FFF",
    search: {
      color: grey[800],
    },
    indicator: {
      background: customBlue[600],
    },
  },
  footer: {
    color: grey[200],
    background: "#233044",
  },
  sidebar: {
    color: grey[200],
    background: "#233044",
    header: {
      color: grey[200],
      background: "#233044",
      brand: {
        color: customBlue[500],
      },
    },
    footer: {
      color: grey[200],
      background: "#1E2A38",
      online: {
        background: green[500],
      },
    },
    badge: {
      color: "#FFF",
      background: customBlue[500],
    },
  },
  appbar: {
    color: "#000000",
    background: "#ffffff",
    boxShadow: "0px 0px 22px -8px #000000",
  },
  zIndex: {
    tooltip: 9999999999,
  },
};

const darkVariant = merge(defaultVariant, {
  name: THEMES.DARK,
  palette: {
    mode: "dark",
    primary: {
      main: customBlue[600],
      contrastText: "#FFF",
    },
    background: {
      default: "#1B2635",
      paper: "#233044",
    },
    text: {
      primary: "rgba(255, 255, 255, 0.95)",
      secondary: "rgba(255, 255, 255, 0.5)",
    },
    active: customBlue[600],
    selected: customBlue[400],
  },
  gridItem: {
    background: "#1B2635",
    color: "white",
  },
  header: {
    color: grey[300],
    background: "#1B2635",
    search: {
      color: grey[200],
    },
  },
  footer: {
    color: grey[300],
    background: "#233044",
  },
  appbar: {
    color: grey[300],
    background: "#26364c",
    boxShadow: "0px 0px 22px -8px #000000",
  },
});

const lightVariant = merge(defaultVariant, {
  name: THEMES.LIGHT,
  palette: {
    mode: "light",
  },
  header: {
    color: grey[200],
    background: customBlue2[500],
    search: {
      color: grey[100],
    },
    indicator: {
      background: red[700],
    },
  },
  sidebar: {
    color: grey[900],
    background: "#FFF",
    header: {
      color: "#FFF",
      background: customBlue2[500],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: grey[50],
      background: customBlue2[500],
      online: {
        background: green[500],
      },
    },
  },
});

const blueVariant = merge(defaultVariant, {
  name: THEMES.BLUE,
  palette: {
    mode: "light",
    primary: {
      background: "#1E2A38",
    },
  },
  sidebar: {
    color: grey[900],
    background: "#FFF",
    header: {
      color: "#FFF",
      background: customBlue2[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: customBlue2[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#FFF",
      background: customBlue2[800],
    },
  },
});

const redVariant = merge(defaultVariant, {
  name: THEMES.RED,
  palette: {
    primary: {
      main: customRed[800],
      contrastText: "#FFF",
    },
    secondary: {
      main: customRed[500],
      contrastText: "#FFF",
    },
    active: customRed[800],
    selected: customRed[500],
  },
  header: {
    color: customRed[200],
    background: customRed[500],
    search: {
      color: customRed[100],
    },
    indicator: {
      background: customBlue[700],
    },
  },
  sidebar: {
    color: grey[800],
    background: "#FFF",
    header: {
      color: "#FFF",
      background: customRed[500],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: grey[50],
      background: customRed[500],
      online: {
        background: green[500],
      },
    },
    badge: {
      color: "#FFF",
      background: customRed[500],
    },
  },
});

const variants = [
  defaultVariant,
  darkVariant,
  lightVariant,
  blueVariant,
  redVariant,
];

export default variants;
