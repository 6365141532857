import { PublicClientApplication } from "@azure/msal-browser";
import { Slide } from "@mui/material";
import * as Sentry from "@sentry/react";

export const msalConfig = {
  auth: {
    clientId: "bf1a3ddb-a7d5-4918-805e-ae852deac8ae",
    authority:
      "https://login.microsoftonline.com/12857881-81b8-4a42-a8d9-b6ceed875e03",
    validateAuthority: true,
    navigateToLoginRequestUrl: true,
    redirectUri: process.env.REACT_APP_MSAL_LOGIN_REDIRECT_URI,
    postLogoutRedirectUri: process.env.REACT_APP_MSAL_LOGOUT_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  scopes: {
    authRequest: {
      scopes: [
        "User.Read",
        "User.ReadBasic.All",
        "Directory.Read.All",
        "ChannelMessage.Read.All",
      ],
    },
    originApiRequest: {
      scopes: ["api://3d5b03e1-1363-4a49-b6c5-60321e4b31c8/access_as_user"],
    },
    personnelApiRequest: {
      scopes: ["api://31cd524c-79e5-430a-9aff-631349cd5b2b/access_as_user"],
    },
  },
  AccessTokenGetter: async function GetAccessToken(scope) {
    const msalInstance = new PublicClientApplication(msalConfig);

    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
      return "";
    }
    const request = {
      ...scope,
      account: accounts[0],
    };

    const accessToken = await msalInstance
      .acquireTokenSilent(request)
      .then((response) => response.accessToken)
      .catch((e) =>
        msalInstance
          .acquireTokenRedirect(request)
          .then((response) => response.accessToken)
      );

    return accessToken;
  },
};

export const graphEndpoints = {
  personByName: (name) =>
    `https://graph.microsoft.com/v1.0/users?$search="displayName:${name}"&$select=displayName,id,mail,jobTitle,department,officeLocation,employeeId,country,city`,
  personByAzureId: (azureId) =>
    `https://graph.microsoft.com/v1.0/users/${azureId}?$select=displayName,id,mail,jobTitle,department,officeLocation,employeeId,country,city`,
  photoByEmail: (uniqueIdentifier) =>
    `https://graph.microsoft.com/v1.0/users/${uniqueIdentifier}/photos/48x48/$value`,
};

export const apiEndpoints = {
  originApiUrl: process.env.REACT_APP_API_URL,
  personnelApiUrl: process.env.REACT_APP_PERSONNEL_URL,
  msGraphApiUrl: "https://graph.microsoft.com/v1.0",
};

export const mapBoxConfig = {
  accessToken:
    "pk.eyJ1IjoiZWNzc3VwcG9ydCIsImEiOiJja2tmYXNiOXAwcnNhMnBxdHQwNmtxM2Z5In0.rdyyhVetLLXgf3MkNX7u5Q",
  id: "mapbox/streets-v11",
  tileSize: 512,
  zoomOffset: -1,
  maxZoom: 18,
};

export const sentryConfig = {
  dsn: "https://27d22212af32934081c972ac4a4d701c@o4504928179716096.ingest.sentry.io/4505940481081344",
  release: process.env.REACT_APP_VERSION,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ["localhost", "https://ecsauto.eu"],
    }),
    new Sentry.Replay(),
  ],
  traceSampleRate: 1,
  replaySessionSampeRate: 0.1,
  replayOnErrorSampleRate: 1.0,
  environment: process.env.REACT_APP_SENTRY_ENV,
  initialScope: (scope) => {
    scope.setTags({ runtime: "typescript" }, { app: "client" });
  },
};

export const snackbarConfig = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right",
  },
  TransitionComponent: Slide,
};
