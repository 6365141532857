import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { Person } from "../../models/Core";
import { ISectionStatus } from "../../models/ISectionStatus";
import { Event } from "../../models/Planning";
import { AsyncThunkFactoryGet } from "../../utils/NetworkUtils";

export interface IDashboardSlice {
  allocatedFreePlanned:
    | {
        year: number;
        personId: number;
        person: Person;
        totalDays: number;
        quotedDays: number;
        allocatedDays: number;
        plannedDays: number;
        toPlan: number;
        remaining: number;
      }
    | undefined;
  nearestEvent: Event | undefined;
  sectionStatuses: {
    allocatedFreePlanned: ISectionStatus;
    nearestEvent: ISectionStatus;
  };
}

const initialState: IDashboardSlice = {
  allocatedFreePlanned: undefined,
  nearestEvent: undefined,
  sectionStatuses: {
    allocatedFreePlanned: { status: "idle", error: undefined },
    nearestEvent: { status: "idle", error: undefined },
  },
};

export const getAllocatedFreePlanned = AsyncThunkFactoryGet<any>(
  "dashboard/allocatedFreePlanned",
  "dashboard/planning/plannedfreeallocated"
);

export const getNearestEvent = AsyncThunkFactoryGet<any>(
  "dashboard/nearestPlannedEvent",
  "dashboard/planning/nearestPlannedEvent"
);

function ActionReducerBuilderGet<T>(
  builder: ActionReducerMapBuilder<IDashboardSlice>,
  f: any,
  field: keyof IDashboardSlice,
  statusField: keyof IDashboardSlice["sectionStatuses"],
  manipulateResult?: (result: T) => any
) {
  builder
    .addCase(f.pending, (state) => {
      // state.sectionStatuses[statusField].status = "loading";
    })
    .addCase(f.fulfilled, (state, action) => {
      // state.sectionStatuses[statusField].status = "succeeded";
      state[field] =
        manipulateResult !== undefined
          ? manipulateResult(action.payload.result)
          : action.payload.result;
    })
    .addCase(f.rejected, (state, action) => {
      // state.sectionStatuses[statusField].status = "error";
      // state.sectionStatuses[statusField].error = action.error.message;
    });
}

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      switch (action.payload) {
      }
    },
    resetCore: () => {},
  },
  extraReducers: (builder) => {
    ActionReducerBuilderGet(
      builder,
      getAllocatedFreePlanned,
      "allocatedFreePlanned",
      "allocatedFreePlanned"
    );

    ActionReducerBuilderGet(
      builder,
      getNearestEvent,
      "nearestEvent",
      "nearestEvent"
    );
  },
});

export default dashboardSlice.reducer;
