// Themes
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  RED: "RED",
  // GREEN: "GREEN",
  // INDIGO: "INDIGO",
};
