import { useEffect, useState, useMemo } from "react";
import styled from "styled-components/macro";
import ReactPerfectScrollbar from "react-perfect-scrollbar";
import {
  List,
  Box,
  Autocomplete as MuiAutocomplete,
  TextField,
  ListItem as MuiListItem,
  CircularProgress,
} from "@mui/material";
import useAppSelector from "../../hooks/useAppSelector";
import { Star } from "react-feather";
import SidebarNavSection from "./SidebarNavSection";
import "../../vendor/perfect-scrollbar.css";
import useAppDispatch from "../../hooks/useAppDispatch";
import { useTranslation } from "react-i18next";
import { RoleGuard, MatchType, Role } from "../Guards";
import {
  uiRestrictedPeopleAsDict,
  getPersonToImpersonate,
  resetImpersonation,
  selectUserOrImpersonationAsDict,
  getRestrictedPersonnel,
} from "../../redux/slices/ui";
import {
  hasNullOrUndefined,
  hasEmptyArrayOrEmptyObject,
} from "../../utils/generalHelpers";

const Items = styled.div`
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
`;

const ListItem = styled(MuiListItem)`
  padding-top: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-bottom: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-left: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 14 : 8)};
  padding-right: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 4 : 7)};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
`;

const Autocomplete = styled(MuiAutocomplete)`
width: 100%,
"& .MuiInputBase-input": {
  color: white,
},
"& .MuiInputLabel-root": { color: "white",
  '& fieldset': {            // - The <fieldset> inside the Input-root
      borderColor: 'white',   // - Set the Input border
  },
  '&:hover fieldset': {
      borderColor: 'white', // - Set the Input border when parent has :hover
  },
  '&.Mui-focused fieldset': { // - Set the Input border when parent is focused 
      borderColor: 'white',
  },
},
`;

const SidebarNav = ({ items }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const selectFavouriteProjects = useAppSelector(
    (x) => x.ui.favouritedProjects
  );
  const [favouriteProjects, setFavouriteProjects] = useState({
    title: "Favourite Projects",
    pages: [],
  });
  const selectUi = useAppSelector((x) => x.ui);
  const persons = useAppSelector(uiRestrictedPeopleAsDict);
  const selectUser = useAppSelector(selectUserOrImpersonationAsDict);
  const [sendingImpersonate, setSendingImpersonate] = useState(false);

  async function handleChange(event, value, reason) {
    if (sendingImpersonate === false) {
      setSendingImpersonate(true);
      if (reason === "clear" || value?.value === selectUi.person.personId) {
        await dispatch(resetImpersonation());
      } else {
        await dispatch(getPersonToImpersonate(`${value?.value}`));
      }
      dispatch(getRestrictedPersonnel());
      setSendingImpersonate(false);
    }
  }

  const ExpensiveSidebarRenderer = useMemo(() => {
    return items.map((item) => (
      <SidebarNavSection
        component="div"
        key={item.title}
        pages={item.pages}
        title={t(item.title)}
        roles={item.roles}
        matchType={item.matchType}
      />
    ));
  }, [t, items]);

  useEffect(() => {
    if (selectFavouriteProjects !== undefined) {
      setFavouriteProjects({
        title: "Favourite Projects",
        pages: selectFavouriteProjects?.map((x) => {
          return {
            href: `/projects/${x?.projectId}`,
            icon: Star,
            title: `${x?.project?.title ?? ""}`,
          };
        }),
      });
    }
  }, [selectFavouriteProjects]);

  const isLoadingImpersonate = useMemo(
    () =>
      hasNullOrUndefined([selectUser, persons]) ||
      hasEmptyArrayOrEmptyObject([selectUser, persons]),
    [selectUser, persons]
  );

  return (
    <ReactPerfectScrollbar options={{ wheelPropagation: false }}>
      <List disablePadding>
        <Items>
          <div>
            <RoleGuard
              matchType={MatchType.Single}
              roles={[
                Role.GroupAdmin,
                Role.Finance,
                Role.Operations,
                Role.RegionalManger,
              ]}
            >
              {isLoadingImpersonate ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: "background.paper",
                    py: 2,
                  }}
                >
                  <CircularProgress color="secondary" />
                </Box>
              ) : (
                <Box
                  sx={{
                    bgcolor: "background.paper",
                  }}
                >
                  <List>
                    <ListItem>
                      <Autocomplete
                        sx={{
                          width: "100%",
                        }}
                        value={selectUser}
                        onChange={handleChange}
                        options={persons ?? []}
                        getOptionLabel={(option) => option.label ?? ""}
                        isOptionEqualToValue={(a, b) => a.value === b.value}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Person"
                            focused
                            color={
                              selectUi.impersonation !== undefined
                                ? "warning"
                                : "info"
                            }
                          />
                        )}
                      />
                    </ListItem>
                  </List>
                </Box>
              )}
            </RoleGuard>
          </div>
          {favouriteProjects && favouriteProjects.pages.length > 0 && (
            <SidebarNavSection
              component="div"
              key={favouriteProjects.title}
              pages={favouriteProjects.pages}
              title={t(favouriteProjects.title)}
            />
          )}
          {ExpensiveSidebarRenderer.map((x) => x)}
        </Items>
      </List>
    </ReactPerfectScrollbar>
  );
};

export default SidebarNav;
