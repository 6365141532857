import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CssBaseline,
  Dialog,
  DialogContent,
  LinearProgress,
  Typography,
} from "@mui/material";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { StyledEngineProvider } from "@mui/styled-engine-sc";
import StylesProvider from "@mui/styles/StylesProvider";
import jssPreset from "@mui/styles/jssPreset";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { create } from "jss";
import { SnackbarProvider } from "notistack";
import { Suspense, useEffect, useMemo, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useIdleTimer } from "react-idle-timer";
import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "styled-components/macro";
import Loader from "./components/Loader";
import { snackbarConfig } from "./config";
import useTheme from "./hooks/useTheme";
import "./i18n";
import routes from "./routes";
import "./styles/PrintStyling.css";
import createTheme from "./theme";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

function App() {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const content = useRoutes(routes);
  const { theme: themeName } = useTheme();

  const timeout = 870000;
  const promptTimeout = 1000 * 30;

  // Modal open state
  const [open, setOpen] = useState(false);

  // Time before idle
  const [remaining, setRemaining] = useState(0);

  const onPrompt = () => {
    // onPrompt will be called after the timeout value is reached
    // In this case 30 minutes. Here you can open your prompt.
    // All events are disabled while the prompt is active.
    // If the user wishes to stay active, call the `reset()` method.
    // You can get the remaining prompt time with the `getRemainingTime()` method,
    if (isAuthenticated) {
      setOpen(true);
      setRemaining(promptTimeout);
    }
  };

  const onIdle = () => {
    // onIdle will be called after the promptTimeout is reached.
    // In this case 30 seconds. Here you can close your prompt and
    // perform what ever idle action you want such as log out your user.
    // Events will be rebound as long as `stopOnMount` is not set.
    if (isAuthenticated) {
      setOpen(false);
      setRemaining(0);
      instance.logoutRedirect(accounts[0]);
    }
  };

  const onActive = () => {
    // onActive will only be called if `reset()` is called while `isPrompted()`
    // is true. Here you will also want to close your modal and perform
    // any active actions.
    if (isAuthenticated) {
      setOpen(false);
      setRemaining(0);
    }
  };

  const { getRemainingTime, isPrompted, activate } = useIdleTimer({
    timeout,
    promptTimeout,
    onPrompt,
    onIdle,
    onActive,
  });

  const handleStillHere = () => {
    setOpen(false);
    activate();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  return (
    <HelmetProvider>
      <Helmet titleTemplate="%s | ECS Auto" defaultTitle="ECS Auto" />
      <StylesProvider jss={jss}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={createTheme(themeName)}>
              <CssBaseline />
              <ThemeProvider theme={createTheme(themeName)}>
                <SnackbarProvider {...snackbarConfig}>
                  <Suspense fallback={<Loader />}>{content}</Suspense>
                  <IdleModal
                    open={open}
                    handleStillHere={handleStillHere}
                    remaining={remaining}
                  />
                </SnackbarProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
      </StylesProvider>
    </HelmetProvider>
  );
}

function IdleModal({ open, handleStillHere, remaining }) {
  const remainingMemo = useMemo(
    () => (remaining > 30 ? 30 : remaining),
    [remaining]
  );

  const barProgress = useMemo(
    () => Math.ceil((remainingMemo / 30) * 100),
    [remainingMemo]
  );

  return (
    <Dialog open={open} maxWidth="sm">
      <DialogContent>
        <Card variant="outlined">
          <CardHeader title="Session Timeout" />
          <CardContent>
            <Box sx={{ width: "100%" }}>
              <Typography>
                You will automatically be logged out in{" "}
                <strong>{remainingMemo}</strong> seconds
              </Typography>
              <LinearProgress
                sx={{ pt: 2 }}
                variant="determinate"
                value={barProgress}
              />
            </Box>
          </CardContent>
          <CardActions>
            <Button onClick={handleStillHere}>Continue</Button>
          </CardActions>
        </Card>
      </DialogContent>
    </Dialog>
  );
}

export default App;
