import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import {
  Feedback,
  FeedbackType,
  FeedbackStatus,
  FeedbackComment,
} from "../../models/Feedback";
import { ISectionStatus } from "../../models/ISectionStatus";
import { IAutocompleteOption } from "../../models/UI";
import {
  AsyncThunkFactoryDelete,
  AsyncThunkFactoryGet,
  AsyncThunkFactoryPost,
  AsyncThunkFactoryPut,
} from "../../utils/NetworkUtils";
import { RootState } from "../store";

export interface IFeedbackSliceState {
  feedbackTypes: FeedbackType[];
  feedbackStatuses: FeedbackStatus[];
  feedback: Feedback[];
  editFeedback: Feedback;

  sectionStatuses: {
    feedbackTypes: ISectionStatus;
    feedbackStatuses: ISectionStatus;
    feedback: ISectionStatus;
    editFeedback: ISectionStatus;
    feedbackComment: ISectionStatus;
  };
}

const emptyFeedback = {} as Feedback;

const initialState = {
  feedbackTypes: [],
  feedbackStatuses: [],
  feedback: [],
  editFeedback: emptyFeedback,
  sectionStatuses: {
    feedbackTypes: { status: "idle", error: "" },
    feedbackStatuses: { status: "idle", error: "" },
    feedback: { status: "idle", error: "" },
    editFeedback: { status: "idle", error: "" },
    feedbackComment: { status: "idle", error: "" },
  },
} as IFeedbackSliceState;

export const getFeedbackTypes = AsyncThunkFactoryGet<FeedbackType[]>(
  "feedback/getFeedbackTypes",
  "feedbacktypes"
);

export const getFeedbackStatuses = AsyncThunkFactoryGet<FeedbackStatus[]>(
  "feedback/getFeedbackStatuses",
  "feedbackstatus"
);

export const getFeedbackFromUser = AsyncThunkFactoryGet<Feedback[]>(
  "feedback/getFeedbackForUser",
  "feedback/userfeedback"
);

export const getFeedbackById = AsyncThunkFactoryGet<Feedback[]>(
  "feedback/getFeedbackById",
  "feedback"
);

export const getAllFeedback = AsyncThunkFactoryGet<Feedback[]>(
  "feedback/getAllFeedback",
  "feedback"
);

export const putFeedback = AsyncThunkFactoryPut<Feedback>(
  "feedback/putFeedback",
  "feedback",
  "Successfully updated feedback",
  "Failed to update feedback"
);

export const postFeedback = AsyncThunkFactoryPost<Feedback>(
  "feedback/postFeedback",
  "feedback",
  "Successfully added feedback",
  "Failed to add feedback"
);

export const deleteFeedback = AsyncThunkFactoryDelete<Feedback>(
  "feedback/deleteFeedback",
  "feedback",
  "Successfully deleted Feedback",
  "Failed to delete Feedback"
);

export const postFeedbackComment = AsyncThunkFactoryPost<FeedbackComment>(
  "feedback/postFeedbackComment",
  "feedbackComment",
  "Successfully added feedback comment",
  "Failed to add feedback comment"
);

function ActionReducerBuilderGet<T>(
  builder: ActionReducerMapBuilder<IFeedbackSliceState>,
  f: any,
  field: keyof IFeedbackSliceState,
  statusField: keyof IFeedbackSliceState["sectionStatuses"],
  manipulateResult?: (result: T) => any
) {
  builder
    .addCase(f.pending, (state) => {
      state.sectionStatuses[statusField].status = "loading";
    })
    .addCase(f.fulfilled, (state, action) => {
      state.sectionStatuses[statusField].status = "succeeded";
      state[field] =
        manipulateResult !== undefined
          ? manipulateResult(action.payload.result)
          : action.payload.result;
    })
    .addCase(f.rejected, (state, action) => {
      state.sectionStatuses[statusField].status = "error";
      state.sectionStatuses[statusField].error = action.error.message;
    });
}

function ActionReducerBuilderPost<T>(
  builder: ActionReducerMapBuilder<IFeedbackSliceState>,
  f: any,
  field: keyof IFeedbackSliceState,
  statusField: keyof IFeedbackSliceState["sectionStatuses"],
  shouldAppendResult: boolean,
  manipulateResult?: (result: T) => any
) {
  builder
    .addCase(f.pending, (state) => {
      state.sectionStatuses[statusField].status = "loading";
    })
    .addCase(f.fulfilled, (state, action) => {
      state.sectionStatuses[statusField].status = "succeeded";
      if (
        action.payload.status === 201 &&
        action.payload.result &&
        shouldAppendResult
      ) {
        var manipulated =
          manipulateResult !== undefined
            ? manipulateResult(action.payload.result)
            : action.payload.result;

        if (Array.isArray(state[field])) {
          (state[field] as any) = (state[field] as any).concat(manipulated);
        }
      }
    })
    .addCase(f.rejected, (state, action) => {
      state.sectionStatuses[statusField].status = "error";
      state.sectionStatuses[statusField].error = action.error.message;
    });
}

function ActionReducerBuilderDelete<T>(
  builder: ActionReducerMapBuilder<IFeedbackSliceState>,
  f: any,
  field: keyof IFeedbackSliceState,
  statusField: keyof IFeedbackSliceState["sectionStatuses"],
  predicate?: (element: T) => boolean
) {
  builder
    .addCase(f.pending, (state) => {
      state.sectionStatuses[statusField].status = "loading";
    })
    .addCase(f.fulfilled, (state, action) => {
      state.sectionStatuses[statusField].status = "succeeded";
      if (action.payload.status === 204 && predicate) {
        (state[field] as any[]) = (state[field] as any[]).filter(
          predicate,
          action.meta.arg
        );
      }
    })
    .addCase(f.rejected, (state, action) => {
      state.sectionStatuses[statusField].status = "error";
      state.sectionStatuses[statusField].error = action.error.message;
    });
}

function ActionReducerBuilderPut(
  builder: ActionReducerMapBuilder<IFeedbackSliceState>,
  f: any,
  field: keyof IFeedbackSliceState,
  statusField: keyof IFeedbackSliceState["sectionStatuses"]
) {
  builder
    .addCase(f.pending, (state) => {
      state.sectionStatuses[statusField].status = "loading";
    })
    .addCase(f.fulfilled, (state, action) => {
      state.sectionStatuses[statusField].status = "succeeded";
    })
    .addCase(f.rejected, (state, action) => {
      state.sectionStatuses[statusField].status = "error";
      state.sectionStatuses[statusField].error = action.error.message;
    });
}

export const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    ActionReducerBuilderGet(
      builder,
      getFeedbackTypes,
      "feedbackTypes",
      "feedbackTypes"
    );

    ActionReducerBuilderGet(
      builder,
      getFeedbackStatuses,
      "feedbackStatuses",
      "feedbackStatuses"
    );

    ActionReducerBuilderGet(builder, getAllFeedback, "feedback", "feedback");

    ActionReducerBuilderGet(
      builder,
      getFeedbackFromUser,
      "feedback",
      "feedback"
    );

    ActionReducerBuilderGet(
      builder,
      getFeedbackById,
      "editFeedback",
      "editFeedback"
    );

    ActionReducerBuilderPut(
      builder,
      putFeedback,
      "editFeedback",
      "editFeedback"
    );

    ActionReducerBuilderDelete(
      builder,
      deleteFeedback,
      "feedback",
      "feedback"
      // function CheckFeedback(this: Feedback, element: Feedback) {
      //   return element.feedbackId === this.feedbackId;
      // }
    );

    ActionReducerBuilderPost(
      builder,
      postFeedback,
      "feedback",
      "feedback",
      false
    );

    ActionReducerBuilderPost(
      builder,
      postFeedbackComment,
      "editFeedback",
      "editFeedback",
      false
    );
  },
});

export const selectFeedbackTypesAsDict = (
  state: RootState
): IAutocompleteOption[] =>
  state.feedback.feedbackTypes
    .map((x) => ({
      value: x.feedbackTypeId,
      label: x.name,
    }))
    .sort((a, b) => a?.label?.localeCompare(b?.label));

export const selectFeedbackStatusesAsDict = (
  state: RootState
): IAutocompleteOption[] =>
  state.feedback.feedbackStatuses
    .map((x) => ({
      value: x.feedbackStatusId,
      label: x.name,
    }))
    .sort((a, b) => a?.label?.localeCompare(b?.label));

// export const {} = feedbackSlice.actions;

export default feedbackSlice.reducer;
