import { useMsal } from "@azure/msal-react";
import { useMemo } from "react";
import {
  AccountInfoHasAuthorization,
  MatchType,
  Role,
} from "../../components/Guards";

export function useHasAuthorization({
  roles,
  matchType,
  ignoreAdminOverride,
}: {
  roles: Role[];
  matchType: MatchType;
  ignoreAdminOverride?: boolean;
}): boolean {
  const { accounts } = useMsal();
  const hasAuthorization = useMemo(
    () =>
      AccountInfoHasAuthorization(
        accounts[0],
        matchType,
        roles,
        ignoreAdminOverride
      ),
    [accounts, matchType, roles, ignoreAdminOverride]
  );

  return hasAuthorization;
}
