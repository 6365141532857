import { GridFilterModel, GridSortModel } from "@mui/x-data-grid";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiEndpoints, msalConfig } from "../../config";
import { AuditLog, AuditLogsViewModel } from "../../models/Logging";

export const loggingApi = createApi({
  reducerPath: "loggingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiEndpoints.originApiUrl}/logging/`,
    prepareHeaders: async (headers, api) => {
      headers.append(
        "Authorization",
        `Bearer ${await msalConfig.AccessTokenGetter(
          msalConfig.scopes.originApiRequest
        )}`
      );
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAuditLogs: builder.query<
      AuditLogsViewModel,
      {
        pageSize: number;
        currentPage: number;
        queryOptions: {
          filterModel: GridFilterModel;
          sortingModel: GridSortModel;
        };
      }
    >({
      query: ({ pageSize, currentPage, queryOptions }) => ({
        url: `auditlogs/GetAuditLogs/${pageSize}/${currentPage}`,
        method: "POST",
        body: {
          filterOperator: queryOptions?.filterModel?.items[0].operatorValue,
          filterValue: queryOptions?.filterModel?.items[0].value ?? "",
          filterColumn: queryOptions?.filterModel?.items[0].columnField,
          sortingColumn: queryOptions?.sortingModel?.at(0)?.field ?? "",
          sortingDirection: queryOptions?.sortingModel?.at(0)?.sort ?? "",
        },
      }),
    }),
    getAuditLog: builder.query<AuditLog, number>({
      query: (id) => `auditlogs/${id}`,
    }),
  }),
});

export const { useGetAuditLogQuery, useGetAuditLogsQuery } = loggingApi;
