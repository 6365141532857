import React from "react";
import { useNavigate } from "react-router-dom";

import { IconButton, Tooltip } from "@mui/material";
import { Info } from "react-feather";

function NavbarTermsButton() {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Tooltip title="Info">
        <IconButton
          color="inherit"
          onClick={() => navigate("/ecs/terms")}
          size="large"
        >
          <Info />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  );
}

export default NavbarTermsButton;
