import { apiEndpoints, msalConfig } from "../../config";

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetChannelMessagesReply, TeamsMessage } from "../../models/IMSGraph";

export const msGraphApi = createApi({
  reducerPath: "msGraphApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiEndpoints.msGraphApiUrl}/`,
    prepareHeaders: async (headers, api) => {
      headers.append(
        "Authorization",
        `Bearer ${await msalConfig.AccessTokenGetter(
          msalConfig.scopes.authRequest
        )}`
      );
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUserData: builder.query<any, void>({
      query: () => "me",
    }),
    getUserPicture: builder.query<any, string | undefined>({
      query: (usersAzureObjectId) => ({
        url: `${
          usersAzureObjectId !== undefined
            ? `users/${usersAzureObjectId}`
            : "me"
        }/photos/240x240/$value`,
        headers: {
          "content-type": "image/jpeg",
        },
        responseHandler: (response) =>
          response.blob().then((resp) => URL.createObjectURL(resp)),
      }),
    }),
    getAllPeople: builder.query<any, void>({
      query: () =>
        `users/?$filter=(accountEnabled eq true) and userType eq 'Member'&$select=DisplayName,UserPrincipalName,employeeId`,
      transformResponse: (ret: {
        "@odata.context": string;
        value: { displayName: string; employeeId: number }[];
      }) => {
        return ret.value
          .filter((x) => x.employeeId !== undefined && x.employeeId !== null)
          .map((x: { employeeId: any; displayName: any }) => {
            return { id: x.employeeId, display: x.displayName };
          });
      },
    }),
    getLatestGeneralChannelMessage: builder.query<TeamsMessage[], void>({
      query: () =>
        "teams/1aee50fd-6da1-49f8-9d6e-5d08d9bfef97/channels/19:c5d3ca1baee34c4287579b68eef17014@thread.skype/messages",
      transformResponse: (ret: GetChannelMessagesReply) => {
        var c = ret.value.filter(
          (value, index, array) =>
            value.messageType === "message" &&
            value.from !== null &&
            value.from.user !== null &&
            value.subject
        );
        return c.sort(
          (a, b) =>
            a.createdDateTime.valueOf() ?? 0 - b.createdDateTime.valueOf() ?? 0
        );
      },
    }),
  }),
});

export const {
  useGetAllPeopleQuery,
  useGetUserDataQuery,
  useGetUserPictureQuery,
  useGetLatestGeneralChannelMessageQuery,
} = msGraphApi;
