import { apiEndpoints, msalConfig } from "../../config";
import { ECSApplication, ECSCompany } from "../../models/Core";

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const personnelApi = createApi({
  reducerPath: "personnelApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiEndpoints.personnelApiUrl}/`,
    prepareHeaders: async (headers, api) => {
      headers.append(
        "Authorization",
        `Bearer ${await msalConfig.AccessTokenGetter(
          msalConfig.scopes.personnelApiRequest
        )}`
      );
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getECSCompanies: builder.query<ECSCompany[], void>({
      query: () => `ECSCompanies`,
    }),
    getDocumentation: builder.query<any, void>({
      query: () => `policies`,
    }),
    getTemplates: builder.query<any, void>({
      query: () => `templates`,
    }),
    getECSApps: builder.query<ECSApplication[], void>({
      query: () => `applicationtypes`,
    }),
  }),
});

export const {
  useGetECSCompaniesQuery,
  useGetDocumentationQuery,
  useGetTemplatesQuery,
  useGetECSAppsQuery,
} = personnelApi;
