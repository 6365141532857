import { Tooltip, Typography } from "@mui/material";
import React from "react";
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/TypeScript/hooks";
import { getToDos } from "../../redux/slices/planning";
import { selectUserOrImpersonation } from "../../redux/slices/ui";
import { isNullOrUndefined } from "../../utils/generalHelpers";

const TooltipTypography = React.forwardRef(function TooltipTypography(
  props,
  ref: React.ForwardedRef<HTMLSpanElement>
) {
  const todoNumber = useAppSelector(
    (x) => x.planning.todos?.filter((a) => a.daysOverdue > 0)?.length
  );

  return (
    <Typography
      {...props}
      ref={ref}
      variant="body1"
      children={todoNumber ?? 0}
    />
  );
});

export function ToDoNumberWrapper() {
  const dispatch = useAppDispatch();

  const impersonation = useAppSelector(selectUserOrImpersonation);

  const impersonationId = useMemo(
    () => impersonation.personId,
    [impersonation]
  );

  useEffect(() => {
    if (!isNullOrUndefined(impersonationId)) {
      dispatch(getToDos(`${impersonationId}`));
    }
  }, [dispatch, impersonationId]);

  return (
    <Tooltip title="Overdue To Dos">
      <TooltipTypography />
    </Tooltip>
  );
}
