import {
  Typography as MuiTypography,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { isNullOrUndefined } from "../utils/generalHelpers";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export interface BreadcrumbItemProps {
  order: number;
  title: string | undefined;
  translateTitle?: boolean | undefined;
  href?: string | undefined | null;
}

export interface BasePageProps {
  title: string;
  breadcrumbItems?: BreadcrumbItemProps[];
  children: any;
}

const Typography = styled(MuiTypography)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

export function DashboardBasePage(pageProps: BasePageProps) {
  const { t } = useTranslation();

  const breadcrumbs = useMemo(
    () =>
      (pageProps?.breadcrumbItems ?? [])
        .sort((a, b) => a.order - b.order)
        .map((bi) => {
          var translatedText = "";
          if (!isNullOrUndefined(bi.title)) {
            translatedText =
              (bi.translateTitle ?? true ? t(bi.title as string) : bi.title) ??
              "";
          }
          return isNullOrUndefined(bi.href) ? (
            <Typography key={bi.order}>{translatedText}</Typography>
          ) : (
            <Link key={bi.order} component={NavLink} to={bi.href ?? ""}>
              {translatedText}
            </Link>
          );
        }),
    [pageProps.breadcrumbItems, t]
  );

  const TranslatedTitle = useMemo(
    () => (!isNullOrUndefined(pageProps.title) ? t(pageProps.title) : ""),
    [pageProps.title, t]
  );

  const BreadcrumbElement = useMemo(() => {
    if (breadcrumbs.length >= 1)
      return (
        <>
          <Breadcrumbs aria-label="Breadcrumb">{breadcrumbs}</Breadcrumbs>
          <Divider my={3} />
        </>
      );
    return null;
  }, [breadcrumbs]);

  return (
    <>
      <Helmet title={TranslatedTitle} />

      {BreadcrumbElement}

      {pageProps.children}
    </>
  );
}

export default DashboardBasePage;
