import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover as MuiPopover,
  Link as MuiLink,
  Tooltip,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Bell, Link, X } from "react-feather";
import { useAppDispatch, useAppSelector } from "../../hooks/TypeScript/hooks";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { updateNotification, getNotifications } from "../../redux/slices/ui";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const NotificationHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

function Notification({ id, title, description, url, Icon }) {
  const dispatch = useAppDispatch();
  const [beingAcknowledged, setBeingAcknowledged] = useState();

  const handleAcknowledge = async () => {
    setBeingAcknowledged(true);
    var ret = await dispatch(updateNotification({ parameter: id }));
    if (!ret.payload.hasError) {
      dispatch(getNotifications());
    }
  };

  return (
    <ListItem divider alignItems="center" justifyContent="space-around">
      <ListItemText
        primary={
          url ? (
            <MuiLink as={RouterLink} to={{ pathname: url }} children={title} />
          ) : (
            title
          )
        }
        primaryTypographyProps={{
          variant: "subtitle2",
          color: "textPrimary",
        }}
        secondary={description}
      />
      <Tooltip title="Dismiss">
        <IconButton
          onClick={async () => await handleAcknowledge(id)}
          disabled={beingAcknowledged}
        >
          {beingAcknowledged ? <CircularProgress /> : <X />}
          {/* {beingAcknowledged ? <CircularProgress /> : <Check />} */}
        </IconButton>
      </Tooltip>
    </ListItem>
  );
}

function NavbarNotificationsDropdown() {
  const navigate = useNavigate();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const notificationsSelect = useAppSelector((x) =>
    (x.ui?.notifications ?? [])
      .filter((x) => !x.isAcknowledged)
      .sort((a, b) => new Date(b.created) - new Date(a.created))
  );

  useEffect(() => {
    let notificationTimer = setInterval(() => {
      dispatch(getNotifications());
    }, 2 * 60000);
    return () => clearInterval(notificationTimer);
  }, [dispatch]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Tooltip title="Notifications">
        <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
          <Indicator badgeContent={notificationsSelect?.length}>
            <Bell />
          </Indicator>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <NotificationHeader p={2}>
          <Typography variant="subtitle1" color="textPrimary">
            {notificationsSelect?.length} New Notifications
          </Typography>
        </NotificationHeader>
        <React.Fragment>
          <List disablePadding>
            {notificationsSelect?.slice(0, 5).map((notification) => (
              <Notification
                key={notification.id}
                id={notification.id}
                title={notification.subject}
                description={notification.message}
                Icon={notification.url ? Link : Bell}
                url={notification.url}
              />
            ))}
          </List>
          <Box p={1} display="flex" justifyContent="center">
            <Button size="small" onClick={() => navigate("/notifications")}>
              View all notifications
            </Button>
          </Box>
        </React.Fragment>
      </Popover>
    </React.Fragment>
  );
}

export default NavbarNotificationsDropdown;
