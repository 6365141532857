import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { Person, PersonSettings, Team, Notification } from "../../models/Core";
import { ISectionStatus } from "../../models/ISectionStatus";
import { FavouriteProject, IAutocompleteOption } from "../../models/UI";
import { RootState } from "../store";
import {
  AsyncThunkFactoryDelete,
  AsyncThunkFactoryGet,
  AsyncThunkFactoryPost,
  AsyncThunkFactoryPut,
} from "../../utils/NetworkUtils";

export interface IUISliceState {
  person: Person;
  people: Person[];
  teams: Team[];
  impersonation: Person | undefined;
  notifications: Notification[];
  favouritedProjects: FavouriteProject[];
  personSettings: PersonSettings | undefined;
  calendarStartDate: Date | undefined;
  sectionStatuses: {
    person: ISectionStatus;
    teams: ISectionStatus;
    people: ISectionStatus;
    impersonation: ISectionStatus;
    notifications: ISectionStatus;
    favouritedProjects: ISectionStatus;
    personSettings: ISectionStatus;
  };
}

const initialState = {
  person: {} as Person,
  people: [],
  teams: [],
  impersonation: undefined,
  notifications: [],
  favouritedProjects: [],
  personSettings: undefined,
  calendarStartDate: undefined,
  sectionStatuses: {
    people: { status: "idle", error: "" },
    teams: { status: "idle", error: "" },
    person: { status: "idle", error: "" },
    impersonation: { status: "idle", error: "" },
    notifications: { status: "idle", error: "" },
    favouritedProjects: { status: "idle", error: "" },
    personSettings: { status: "idle", error: "" },
  },
} as IUISliceState;

export const getPersonToImpersonate = AsyncThunkFactoryGet<Person>(
  "ui/getPersonToImpersonate",
  "person"
);

export const getSelf = AsyncThunkFactoryGet<Person>(
  "ui/getSelf",
  "person/getself"
);

export const deleteFavouriteProjects =
  AsyncThunkFactoryDelete<FavouriteProject>(
    "ui/deleteFavouriteProjects",
    "ui/favouriteprojects",
    "Successfully unfavourited project",
    "Failed to unfavourite project"
  );

export const getFavouriteProjects = AsyncThunkFactoryGet<FavouriteProject[]>(
  "ui/getFavouriteProjects",
  "ui/favouriteprojects"
);

export const postFavouriteProjects = AsyncThunkFactoryPost<FavouriteProject>(
  "ui/postFavouriteProjects",
  "ui/favouriteprojects",
  "Successfully favourited project",
  "Failed to favourite project"
);

export const getPersonSettings = AsyncThunkFactoryGet<PersonSettings>(
  "ui/getPersonSettings",
  "person"
);

export const updatePersonSettings = AsyncThunkFactoryPut<PersonSettings>(
  "ui/updatePersonSettings",
  "person",
  "Successfully updated personal settings",
  "Failed to update personal settings"
);

export const updateNotification = AsyncThunkFactoryPut(
  "ui/updateNotification",
  "notifications",
  "Successfully updated notification",
  "Failed to update notification"
);

export const getNotifications = AsyncThunkFactoryGet<Notification[]>(
  "ui/getNotifications",
  "notifications/byperson"
);

export const getRestrictedPersonnel = AsyncThunkFactoryGet<Person[]>(
  "ui/getImpersonatePersonnel",
  "person/restrictedpeople"
);

export const getRestrictedTeams = AsyncThunkFactoryGet<Team[]>(
  "ui/getRestrictedTeams",
  "person/restrictedteams"
);

function ActionReducerBuilderGet<T>(
  builder: ActionReducerMapBuilder<IUISliceState>,
  f: any,
  field: keyof IUISliceState,
  statusField: keyof IUISliceState["sectionStatuses"],
  thenF?: (state: IUISliceState, action: any) => any,
  manipulateResult?: (result: T) => any
) {
  builder
    .addCase(f.pending, (state) => {
      state.sectionStatuses[statusField].status = "loading";
    })
    .addCase(f.fulfilled, (state, action) => {
      state.sectionStatuses[statusField].status = "succeeded";
      state[field] =
        manipulateResult !== undefined
          ? manipulateResult(action.payload.result)
          : action.payload.result;

      if (thenF) thenF(state, action);
    })
    .addCase(f.rejected, (state, action) => {
      state.sectionStatuses[statusField].status = "error";
      state.sectionStatuses[statusField].error = action.error.message;
    });
}

function ActionReducerBuilderPost<T>(
  builder: ActionReducerMapBuilder<IUISliceState>,
  f: any,
  field: keyof IUISliceState,
  statusField: keyof IUISliceState["sectionStatuses"],
  shouldAppendResult: boolean,
  manipulateResult?: (result: T) => any
) {
  builder
    .addCase(f.pending, (state) => {
      state.sectionStatuses[statusField].status = "loading";
    })
    .addCase(f.fulfilled, (state, action) => {
      state.sectionStatuses[statusField].status = "succeeded";
      if (
        action.payload.status === 201 &&
        action.payload.result &&
        shouldAppendResult
      ) {
        var manipulated =
          manipulateResult !== undefined
            ? manipulateResult(action.payload.result)
            : action.payload.result;

        if (Array.isArray(state[field])) {
          (state[field] as any) = (state[field] as any).concat(manipulated);
        }
      }
    })
    .addCase(f.rejected, (state, action) => {
      state.sectionStatuses[statusField].status = "error";
      state.sectionStatuses[statusField].error = action.error.message;
    });
}

function ActionReducerBuilderDelete<T>(
  builder: ActionReducerMapBuilder<IUISliceState>,
  f: any,
  field: keyof IUISliceState,
  statusField: keyof IUISliceState["sectionStatuses"],
  predicate: (element: T) => boolean
) {
  builder
    .addCase(f.pending, (state) => {
      state.sectionStatuses[statusField].status = "loading";
    })
    .addCase(f.fulfilled, (state, action) => {
      state.sectionStatuses[statusField].status = "succeeded";
      if (action.payload.status === 204 && Array.isArray(state[field])) {
        (state[field] as any[]) = (state[field] as any[]).filter(
          predicate,
          action.meta.arg
        );
      }
    })
    .addCase(f.rejected, (state, action) => {
      state.sectionStatuses[statusField].status = "error";
      state.sectionStatuses[statusField].error = action.error.message;
    });
}

function ActionReducerBuilderPut(
  builder: ActionReducerMapBuilder<IUISliceState>,
  f: any,
  field: keyof IUISliceState,
  statusField: keyof IUISliceState["sectionStatuses"]
) {
  builder
    .addCase(f.pending, (state) => {
      state.sectionStatuses[statusField].status = "loading";
    })
    .addCase(f.fulfilled, (state, action) => {
      state.sectionStatuses[statusField].status = "succeeded";
    })
    .addCase(f.rejected, (state, action) => {
      state.sectionStatuses[statusField].status = "error";
      state.sectionStatuses[statusField].error = action.error.message;
    });
}

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetImpersonation: (state) => {
      state.impersonation = undefined;
    },
    setCalendarStartDate: (state, date) => {
      state.calendarStartDate = date.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    ActionReducerBuilderGet(
      builder,
      getPersonToImpersonate,
      "impersonation",
      "impersonation"
    );

    ActionReducerBuilderGet(
      builder,
      getSelf,
      "person",
      "person",
      (state, action) => {
        state.personSettings =
          (action.payload.result as Person).personSettings ??
          ({} as PersonSettings);
        state.favouritedProjects =
          (action.payload.result as Person).favouriteProjects ?? [];
      }
    );

    ActionReducerBuilderGet(
      builder,
      getPersonSettings,
      "personSettings",
      "personSettings"
    );

    ActionReducerBuilderPut(
      builder,
      updatePersonSettings,
      "personSettings",
      "personSettings"
    );

    ActionReducerBuilderGet(
      builder,
      getFavouriteProjects,
      "favouritedProjects",
      "favouritedProjects"
    );

    ActionReducerBuilderPost(
      builder,
      postFavouriteProjects,
      "favouritedProjects",
      "favouritedProjects",
      true
    );

    ActionReducerBuilderDelete(
      builder,
      deleteFavouriteProjects,
      "favouritedProjects",
      "favouritedProjects",
      function compareFP(this: FavouriteProject, element: FavouriteProject) {
        return this.projectId === element.projectId;
      }
    );

    ActionReducerBuilderGet(
      builder,
      getNotifications,
      "notifications",
      "notifications"
    );

    ActionReducerBuilderPut(
      builder,
      updateNotification,
      "notifications",
      "notifications"
    );

    ActionReducerBuilderGet(
      builder,
      getRestrictedPersonnel,
      "people",
      "people"
    );

    ActionReducerBuilderGet(builder, getRestrictedTeams, "teams", "teams");
  },
});

export const selectUserOrImpersonation = (state: RootState) =>
  state.ui?.impersonation ?? state.ui.person;

export const selectUserOrImpersonationAsDict = (
  state: RootState
): IAutocompleteOption => {
  return {
    value: state.ui.impersonation?.personId ?? state.ui.person?.personId,
    label: state.ui.impersonation?.name ?? state.ui.person?.name,
  };
};

export const uiRestrictedPeopleAsDict = (
  state: RootState
): IAutocompleteOption[] =>
  state.ui.people
    ?.map((x) => ({
      value: x.personId,
      label: x.name,
    }))
    .sort((a, b) => a?.label?.localeCompare(b?.label));

export const uiRestrictedTeamAsDict = (
  state: RootState
): IAutocompleteOption[] =>
  state.ui.teams
    .map((x) => ({
      value: x.teamId,
      label: x.name,
    }))
    .sort((a, b) => a?.label?.localeCompare(b?.label));

export const { resetImpersonation, setCalendarStartDate } = uiSlice.actions;

export default uiSlice.reducer;
