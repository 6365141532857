import { useMsal } from "@azure/msal-react";
import React from "react";
import { useLocation } from "react-router-dom";

import reduceChildRoutes from "./reduceChildRoutes";

const SidebarNavList = (props) => {
  const { pages, depth } = props;
  const router = useLocation();
  const currentRoute = router.pathname;

  const { accounts } = useMsal();

  const childRoutes = pages.reduce(
    (items, page) =>
      reduceChildRoutes({
        account: accounts[0],
        items,
        page,
        currentRoute,
        depth,
      }),
    []
  );

  return <React.Fragment>{childRoutes}</React.Fragment>;
};

export default SidebarNavList;
