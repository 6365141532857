import { ActivityType, Customer, Dealer, Person } from "./Core";
import { StatusFlag } from "./IStatusFlag";
import { Project } from "./Project";

export interface ActivitySchedule {
  id: number;

  projectId: string;
  project?: Project;

  customerId: number;
  customer?: Customer | null;
  customerName?: string;

  dealerId: number | undefined;
  dealer?: Dealer;
  dealerName?: string;

  personId: number;
  person?: Person;

  activityTypeId: number;
  activityType?: ActivityType;

  priority: ActivitySchedulePriority;

  month: number;

  year: number;

  days: number;

  plannedEventId?: number;

  plannedActivityType?: string;

  plannedActivityDate?: string;

  statusFlag: StatusFlag;
}

export enum ActivitySchedulePriority {
  Low = 0,
  Normal = 1,
  High = 2,
}

export const Months = [
  { value: 1, label: "Jan", fullLabel: "January" },
  { value: 2, label: "Feb", fullLabel: "February" },
  { value: 3, label: "Mar", fullLabel: "March" },
  { value: 4, label: "Apr", fullLabel: "April" },
  { value: 5, label: "May", fullLabel: "May" },
  { value: 6, label: "Jun", fullLabel: "June" },
  { value: 7, label: "Jul", fullLabel: "July" },
  { value: 8, label: "Aug", fullLabel: "August" },
  { value: 9, label: "Sept", fullLabel: "September" },
  { value: 10, label: "Oct", fullLabel: "October" },
  { value: 11, label: "Nov", fullLabel: "November" },
  { value: 12, label: "Dec", fullLabel: "December" },
];

export const MonthsWithNullOption = [
  { value: null, label: "" },
  { value: 1, label: "Jan", fullLabel: "January" },
  { value: 2, label: "Feb", fullLabel: "February" },
  { value: 3, label: "Mar", fullLabel: "March" },
  { value: 4, label: "Apr", fullLabel: "April" },
  { value: 5, label: "May", fullLabel: "May" },
  { value: 6, label: "Jun", fullLabel: "June" },
  { value: 7, label: "Jul", fullLabel: "July" },
  { value: 8, label: "Aug", fullLabel: "August" },
  { value: 9, label: "Sept", fullLabel: "September" },
  { value: 10, label: "Oct", fullLabel: "October" },
  { value: 11, label: "Nov", fullLabel: "November" },
  { value: 12, label: "Dec", fullLabel: "December" },
];
