import {
  GridColDef,
  GridColType,
  GridColumnVisibilityModel,
  GridEnrichedColDef,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import {
  ExpenseEditorViewModel,
  ExpenseExchangeRate,
  InvoiceScheduleAttachmentItem,
  InvoiceScheduleItem,
  QuotationItem,
  QuotationItemAddOn,
} from "../models/Finance";
import { PlanningAllocation } from "../models/Planning";
import { ActivitySchedule } from "../models/Scheduling";
import * as ExcelJS from "exceljs";
import fileDownload from "js-file-download";

export const priorities = [
  { value: 0, label: "Low" },
  { value: 1, label: "Normal" },
  { value: 2, label: "High" },
];

export const CompareExpenseEditorViewModels = (
  row: ExpenseEditorViewModel,
  expense: ExpenseEditorViewModel | undefined
) => {
  if (expense === undefined) return false;
  return (
    row.expenseId === expense.eventId &&
    row.eventId === expense.eventId &&
    row.currency === expense.currency &&
    row.expenseTypeId === expense.expenseTypeId &&
    row.localAmount === expense.localAmount &&
    row.grossAmount === expense.grossAmount &&
    row.vatAmount === expense.vatAmount &&
    row.netAmount === expense.netAmount
  );
};

export const CompareExpenseExchangeRates = (
  row: ExpenseExchangeRate,
  expenseExchangeRate: ExpenseExchangeRate | undefined
) => {
  if (expenseExchangeRate === undefined) return false;
  return (
    row.expenseExchangeRateId === expenseExchangeRate.expenseExchangeRateId &&
    row.currencyFromId === expenseExchangeRate.currencyFromId &&
    row.currencyToId === expenseExchangeRate.currencyToId &&
    row.rate === expenseExchangeRate.rate &&
    row.month === expenseExchangeRate.month
  );
};

export const ComparePlanningAllocations = (
  row: PlanningAllocation,
  planningAllocation: PlanningAllocation | undefined
) => {
  if (planningAllocation === undefined) return false;
  return (
    row.id === planningAllocation.id &&
    row.activityTypeId === planningAllocation.activityTypeId &&
    row.customerId === planningAllocation.customerId &&
    row.duration === planningAllocation.duration &&
    row.personId === planningAllocation.personId &&
    row.quantity === planningAllocation.quantity &&
    row.suggestedMonth === planningAllocation.suggestedMonth &&
    row.suggestedYear === planningAllocation.suggestedYear
  );
};

export const CompareActivitySchedules = (
  row: ActivitySchedule,
  activitySchedule: ActivitySchedule | undefined
) => {
  if (activitySchedule === undefined) return false;
  return (
    row.id === activitySchedule.id &&
    row.activityTypeId === activitySchedule.activityTypeId &&
    row.days === activitySchedule.days &&
    row.customerId === activitySchedule.customerId &&
    row.dealerId === activitySchedule.dealerId &&
    row.month === activitySchedule.month &&
    row.year === activitySchedule.year &&
    row.personId === activitySchedule.personId &&
    row.priority === activitySchedule.priority &&
    row.projectId === activitySchedule.projectId
  );
};

export const CompareInvoiceScheduleAttachmentItems = (
  row: InvoiceScheduleAttachmentItem,
  attachmentItem: InvoiceScheduleAttachmentItem | undefined
) => {
  if (attachmentItem === undefined) return false;
  return (
    row.id === attachmentItem.id &&
    row.activityTypeId === attachmentItem.activityTypeId &&
    row.invoiceScheduleId === attachmentItem.invoiceScheduleId &&
    row.isBillable === attachmentItem.isBillable
  );
};

export const CompareInvoiceScheduleItems = (
  row: InvoiceScheduleItem | undefined,
  scheduleItem: InvoiceScheduleItem | undefined
) => {
  if (row === undefined || scheduleItem === undefined) return false;
  return (
    row.id === scheduleItem.id &&
    row.date === scheduleItem.date &&
    row.datePaid === scheduleItem.datePaid &&
    row.dateSent === scheduleItem.dateSent
  );
};

export const CompareQuotationItems = (
  row: QuotationItem,
  quotationItem: QuotationItem | undefined
) => {
  if (quotationItem === undefined) return false;
  return (
    row.id === quotationItem.id &&
    row.teamPricingGroupId === quotationItem.teamPricingGroupId &&
    row.countryPricingGroupId === quotationItem.countryPricingGroupId &&
    row.activityTypeId === quotationItem.activityTypeId &&
    row.customerId === quotationItem.customerId &&
    row.duration === quotationItem.duration &&
    row.nonProductiveDuration === quotationItem.nonProductiveDuration &&
    row.quantity === quotationItem.quantity &&
    row.teamItemPrice === quotationItem.teamItemPrice &&
    row.teamItemPriceOverride === quotationItem.teamItemPriceOverride &&
    row.useTeamItemPriceOverride === quotationItem.useTeamItemPriceOverride &&
    row.countryItemPrice === quotationItem.countryItemPrice &&
    row.countryItemPriceOverride === quotationItem.countryItemPriceOverride &&
    row.useCountryItemPriceOverride ===
      quotationItem.useCountryItemPriceOverride &&
    row.estimatedExpenses === quotationItem.estimatedExpenses &&
    row.comment === quotationItem.comment &&
    row.adjustment === quotationItem.adjustment
  );
};

export const CompareQuotationItemAddon = (
  row: QuotationItemAddOn,
  quotationItem: QuotationItemAddOn | undefined
) => {
  if (quotationItem === undefined) return false;
  return (
    row.id === quotationItem.id &&
    row.title === quotationItem.title &&
    row.customerId === quotationItem.customerId &&
    row.quantity === quotationItem.quantity &&
    row.cost === quotationItem.cost &&
    row.adjustment === quotationItem.adjustment &&
    row.comment === quotationItem.comment &&
    row.billableMonth === quotationItem.billableMonth &&
    row.billableYear === quotationItem.billableYear
  );
};

type ExcelColumn = {
  header: string;
  key: string;
  width?: number;
  valueFormmater?: (val: GridValueFormatterParams<any>) => any;
  type?: GridColType;
};

type ExcelRow = Record<string, any>;

export function DataGridColumnToExcelColumn(
  cols: (GridEnrichedColDef | GridColDef)[],
  visibleCols: GridColumnVisibilityModel
) {
  return cols.reduce((prev, c, i) => {
    if (visibleCols[c.field] === undefined || visibleCols[c.field] !== false)
      return [
        ...prev,
        {
          header: c.headerName ?? c.field,
          key: c.field,
          type: c.type ?? "string",
          valueFormmater: c.valueFormatter,
        },
      ];
    return prev;
  }, [] as ExcelColumn[]);
}

export function DataGridRowsToExcelRows(
  rows: any[],
  excelColumns: ExcelColumn[]
): ExcelRow[] {
  return rows.reduce(
    (prev, row, i) => [
      ...prev,
      excelColumns.reduce((p, col, i) => {
        if (row[col.key] !== undefined) {
          return {
            ...p,
            [col.key]:
              col.valueFormmater?.({
                value: row[col.key],
                field: col.key,
                api: undefined,
              }) ?? row[col.key],
          };
        }
        return p;
      }, [] as ExcelRow),
    ],
    [] as ExcelRow[]
  );
}

// export function DataGridRowsToExcelRowsArray(
//   rows: any[],
//   excelColumns: ExcelColumn[]
// ): any[][] {
//   return rows.reduce(
//     (prev, row, i) =>
//       [
//         ...prev,
//         excelColumns.reduce(
//           (p, col, i) => [
//             ...p,
//             row[col.key] === null
//               ? ""
//               : col.valueFormmater?.({
//                   value: row[col.key],
//                   field: col.key,
//                   api: undefined,
//                 }) ?? row[col.key],
//           ],
//           [] as any[]
//         ),
//       ] as any[][]
//   );
// }

export async function exportAsExcel<T>(
  fileName: string,
  sheets: {
    data: T[];
    cols: (GridEnrichedColDef | GridColDef)[];
    visibleCols: GridColumnVisibilityModel;
    name: string;
  }[]
) {
  const workbook = new ExcelJS.Workbook();

  workbook.created = new Date();
  workbook.modified = new Date();

  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 10000,
      height: 20000,
      firstSheet: 0,
      activeTab: 1,
      visibility: "visible",
    },
  ];

  sheets.forEach(({ cols, data, visibleCols, name }, i) => {
    var excelCols = DataGridColumnToExcelColumn(cols, visibleCols);
    var rows = DataGridRowsToExcelRows(data, excelCols);
    // var rowsArr = DataGridRowsToExcelRowsArray(data, excelCols);

    const sheet = workbook.addWorksheet(name);
    sheet.columns = excelCols;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _ = sheet.addRows(rows);

    sheet.columns.forEach((column) => {
      const lengths = (column?.values ?? []).map((v) =>
        v !== null && v !== undefined ? v.toString().length : 0
      );
      const maxLength = Math.max(
        ...lengths.filter((v) => typeof v === "number")
      );
      column.width = maxLength < 10 ? 10 : maxLength;
    });
  });

  const buffer = await workbook.xlsx.writeBuffer();

  fileDownload(buffer, fileName + ".xlsx", "application/octet-stream");
}
