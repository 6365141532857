export interface TeamsMessage {
  createdDateTime: Date;
  subject: string;
  importance: MessageImportance;
  webUrl: string;
  from: {
    user: TeamsUser;
  };
  messageType: string;
}

export enum MessageImportance {
  Normal = "normal",
  High = "high",
}

export interface TeamsUser {
  id: string;
  displayName: string;
  userIdentityType: string;
}

export interface GetChannelMessagesReply {
  "@odata.context": string;
  "@odata.count": number;
  value: TeamsMessage[];
}

export interface IMSGraphPerson {
  id: string;
  displayName: string;
  mail: string;
  jobTitle: string;
  photo: string;
  department: string;
  officeLocation: string;
  employeeId: number;
  prefferedLanguage: string;
  country: string;
  city: string;
}

export enum IMSGraphPersonIndentifierType {
  Email = "mail",
  Name = "displayName",
  Id = "id",
}

export type MSGraphPersonContextType = {
  people: IMSGraphPerson[];
  requestPeople: (
    uniqueIdentifier: string[],
    uniqueIdentifierType: IMSGraphPersonIndentifierType
  ) => Promise<void>;
  requestPerson: (
    uniqueIdentifier: string,
    uniqueIdentifierType: IMSGraphPersonIndentifierType
  ) => Promise<IMSGraphPerson | undefined>;
};
