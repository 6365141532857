import { graphEndpoints } from "../config";
import axiosInstance from "./axios";
import { msalConfig } from "../config";

export async function getPersonByName(name) {
  var a = await axiosInstance.get(`${graphEndpoints.personByName(name)}`, {
    headers: {
      ConsistencyLevel: "eventual",
      Authorization: `Bearer ${await msalConfig.AccessTokenGetter(
        msalConfig.scopes.authRequest
      )}`,
    },
  });
  return a.data.value[0];
}

export async function getPersonByAzureId(azureId) {
  var a = await axiosInstance.get(
    `${graphEndpoints.personByAzureId(azureId)}`,
    {
      headers: {
        ConsistencyLevel: "eventual",
        Authorization: `Bearer ${await msalConfig.AccessTokenGetter(
          msalConfig.scopes.authRequest
        )}`,
      },
    }
  );
  return a.data;
}

export async function getPhotoByEmail(email) {
  const headers = new Headers();
  const bearer = `Bearer ${await msalConfig.AccessTokenGetter(
    msalConfig.scopes.authRequest
  )}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
    "content-type": "blob",
  };

  return await fetch(`${graphEndpoints.photoByEmail(email)}`, options)
    .then((response) => response.blob())
    .then(function (myBlob) {
      var objectUrl = URL.createObjectURL(myBlob);
      return objectUrl;
    })
    .catch((error) => "error");
}
