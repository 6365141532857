import styled from "styled-components/macro";
import { LoginButton } from "../../components/LoginButton";
import {
  CardContent,
  Card,
  Box,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";

const Typography = styled(MuiTypography)(spacing);

const Content = styled.div`
  padding: ${(props) => props.theme.spacing(6)} 0;
  line-height: 150%;
  text-align: center;
  width: 40vw;
`;

const Title = styled(Typography)`
  opacity: 0.9;
  line-height: 1.4;
  font-size: 1.75rem;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};

  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 1.8rem;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 2rem;
  }

  span {
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

function Introduction() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        direction: "row",
      }}
    >
      <Content>
        <Card>
          <CardContent>
            <Title>ECS Auto</Title>
            <Typography type="h5" color="error">
              AUTHORISED USERS ONLY
            </Typography>
            <LoginButton />
          </CardContent>
        </Card>
      </Content>
    </Box>
  );
}

export default Introduction;
