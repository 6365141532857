import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import * as Sentry from "@sentry/react";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { msalConfig, sentryConfig } from "./config";
import MSGraphPersonProvider from "./contexts/MSGraphPeopleContext";
import { ThemeProvider } from "./contexts/ThemeContext";
import Page500 from "./pages/auth/Page500";
import { store } from "./redux/store";
// import reportWebVitals from "./utils/reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import App from "./App";

Sentry.init(sentryConfig);

const pca = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <BrowserRouter>
    <MsalProvider instance={pca}>
      <Provider store={store}>
        <ThemeProvider>
          <Sentry.ErrorBoundary fallback={<Page500 />}>
            <MSGraphPersonProvider>
              <App />
            </MSGraphPersonProvider>
          </Sentry.ErrorBoundary>
        </ThemeProvider>
      </Provider>
    </MsalProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

serviceWorkerRegistration.register();
