import styled from "styled-components/macro";
import { Formik, Form, FormikErrors, FormikHelpers, Field } from "formik";
import {
  Alert as MuiAlert,
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  Typography,
  Grid,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useAppDispatch, useAppSelector } from "../../hooks/TypeScript/hooks";
import { SelectWrapper, TextFieldWrapper } from "../FormikMUI";
import { useEffect } from "react";
import {
  getFeedbackTypes,
  postFeedback,
  selectFeedbackTypesAsDict,
} from "../../redux/slices/feedback";
import { useSnackbar } from "notistack";
import { IAutocompleteOption } from "../../models/UI";

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Button = styled(MuiButton)(spacing);

export const FeedbackForm = (props: { handleClose: () => void }) => {
  const dispatch = useAppDispatch();
  const person = useAppSelector((x) => x.ui.person);
  const feedbackTypes = useAppSelector(selectFeedbackTypesAsDict);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(getFeedbackTypes());
  }, [dispatch]);

  interface IFormValues {
    feedbackId: 0;
    applicationTypeId: 6;
    feedbackTypeId: IAutocompleteOption;
    message: string;
    personId: number;
    name: string;
    systemVersion: string;
    view: string;
    feedbackStatusId: 0;
  }

  const initialValues = {
    feedbackId: 0,
    applicationTypeId: 6,
    feedbackTypeId: { value: 1, label: "Application" },
    message: "",
    personId: person.personId,
    name: person.name,
    systemVersion: process.env.REACT_APP_VERSION,
    view: window.location.pathname,
    feedbackStatusId: 0,
  } as IFormValues;

  const TryPost = async (values: IFormValues) => {
    return (
      await dispatch(
        postFeedback({
          data: {
            ...values,
            feedbackTypeId: Number(values.feedbackTypeId.value),
          },
          enqueueSnackbar,
        })
      )
    ).payload as any;
  };

  const onSubmit = async (
    values: IFormValues,
    actions: FormikHelpers<IFormValues>
  ) => {
    var result = await TryPost(values);
    if ((result as any).hasError) {
      actions.setStatus({ sent: false });
    } else {
      actions.setStatus({ sent: true });
      props.handleClose();
    }

    actions.setSubmitting(false);
  };

  const onValidate = (values: IFormValues) => {
    let errors: FormikErrors<IFormValues> = {};

    if (!values.message.trim()) errors.message = "Required";

    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount={false}
      onSubmit={onSubmit}
      validate={onValidate}
      displayName={"New Feedback"}
    >
      {({ status, isSubmitting, handleSubmit }) => (
        <Card>
          <CardContent>
            <Typography variant="h3">{"New Feedback"}</Typography>

            <Divider my={3} />

            {status && status.sent && (
              <Alert severity="success" my={3}>
                Successfully added your feedback.
              </Alert>
            )}

            {status && !status.sent && (
              <Alert severity="error" my={3}>
                Error adding your feedback.
              </Alert>
            )}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <Form onSubmit={handleSubmit}>
                <Field
                  name="feedbackTypeId"
                  label="Feedback Type"
                  options={feedbackTypes}
                  component={SelectWrapper}
                  variant="outlined"
                  mb={5}
                />

                <Field
                  name="message"
                  label="Message"
                  component={TextFieldWrapper}
                  multiline
                  rows={5}
                  variant="outlined"
                  fullWidth
                  mb={3}
                />

                <Box>
                  <Grid container>
                    <Grid
                      item
                      xs={6}
                      sx={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <Button
                        disabled={isSubmitting}
                        onClick={() => props.handleClose()}
                        variant="contained"
                        color="secondary"
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        variant="contained"
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
  );
};

export default FeedbackForm;
