export function isNullOrUndefined(obj: any) {
  return obj === null || obj === undefined;
}
export function hasNullOrUndefined(objs: any[]) {
  return objs.some((x) => x === null || x === undefined);
}
export function allNullOrUndefined(objs: any[]) {
  return objs.every((x) => x === null || x === undefined);
}

export function isNullOrUndefinedOrWhitespace(obj: any) {
  return isNullOrUndefined(obj) || obj.trim() === "";
}

export function isEmptyArrayOrEmptyObject(obj: any) {
  if (Array.isArray(obj)) {
    return obj.length < 1;
  } else {
    return Object.keys(obj).length < 1;
  }
}

export function hasEmptyArrayOrEmptyObject(objs: any[]) {
  return objs.some((x) => {
    if (Array.isArray(x)) {
      return x.length < 1;
    } else {
      return Object.keys(x).length < 1;
    }
  });
}

export function initialsFromString(string: string) {
  const names = string.trim().split(" ");
  return names.reduce((prev, curr, index) => {
    if (index === 0 || index === names.length - 1) {
      prev += curr.charAt(0).toUpperCase();
    }
    return prev;
  }, "");
}
