import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiEndpoints, msalConfig } from "../config";
import axiosInstance from "./axios";
import { isNullOrUndefinedOrWhitespace } from "./generalHelpers";

export interface IResult<T> {
  result: T | null;
  status: number;
  hasError: boolean;
  error?: string;
}

// #region Get
export async function GenericGet<T>(url: string, scope: { scopes: string[] }) {
  const response = await axiosInstance.get<T>(url, {
    headers: {
      Authorization: `Bearer ${await msalConfig.AccessTokenGetter(scope)}`,
    },
  });
  return { result: response.data, status: response.status } as IResult<T>;
}

export function AsyncThunkFactoryGet<T>(
  id: string,
  url: string,
  api?: string,
  scope?: { scopes: string[] }
) {
  return createAsyncThunk(id, async (parameter?: string | undefined) => {
    var hasParameter = parameter !== undefined;

    return await GenericGet<T>(
      `${api ?? apiEndpoints.originApiUrl}/${url}${
        hasParameter ? `/${parameter}` : ""
      }`,
      scope ?? msalConfig.scopes.originApiRequest
    );
  });
}
// #endregion

// #region Post
export async function GenericPost<T>(
  url: string,
  scope: { scopes: string[] },
  data: T,
  enqueueSnackbar: any,
  successText: string,
  defaultErrorText: string
) {
  return await axiosInstance
    .post(url, data, {
      headers: {
        Authorization: `Bearer ${await msalConfig.AccessTokenGetter(scope)}`,
      },
    })
    .then((resp) => {
      if (enqueueSnackbar) {
        enqueueSnackbar(successText, {
          variant: "success",
        });
      }
      return {
        result: resp.data,
        status: resp.status,
        hasError: false,
      };
    })
    .catch((err) => {
      if (enqueueSnackbar) {
        var text = "";

        if (!isNullOrUndefinedOrWhitespace(err.data?.title)) {
          text = err.data?.title;
        } else if (!isNullOrUndefinedOrWhitespace(err.data)) {
          text = err.data;
        } else {
          text = defaultErrorText;
        }

        enqueueSnackbar(text, {
          variant: "error",
        });
      }
      return {
        result: null,
        status: err.status,
        hasError: true,
        error: err.data,
      };
    });
}

export function AsyncThunkFactoryPost<T>(
  id: string,
  url: string,
  successText: string,
  defaultErrorText: string,
  api?: string,
  scope?: { scopes: string[] }
) {
  return createAsyncThunk(
    id,
    async ({
      data,
      parameter,
      enqueueSnackbar,
    }: {
      data: T;
      parameter?: string;
      enqueueSnackbar: any;
    }) => {
      var hasParameter = parameter !== undefined;

      return await GenericPost<T>(
        `${api ?? apiEndpoints.originApiUrl}/${url}${
          hasParameter ? `/${parameter}` : ""
        }`,
        scope ?? msalConfig.scopes.originApiRequest,
        data,
        enqueueSnackbar,
        successText,
        defaultErrorText
      );
    }
  );
}
// #endregion

// #region Put
export async function GenericPut<T>(
  url: string,
  scope: { scopes: string[] },
  data: T,
  enqueueSnackbar: any,
  successText: string,
  defaultErrorText: string
): Promise<IResult<T>> {
  return await axiosInstance
    .put(url, data, {
      headers: {
        Authorization: `Bearer ${await msalConfig.AccessTokenGetter(scope)}`,
      },
    })
    .then((resp) => {
      if (enqueueSnackbar) {
        enqueueSnackbar(successText, {
          variant: "success",
        });
      }
      return {
        result: resp.data,
        status: resp.status,
        hasError: false,
      };
    })
    .catch((err) => {
      if (enqueueSnackbar) {
        var text = "";

        if (!isNullOrUndefinedOrWhitespace(err.data?.title)) {
          text = err.data?.title;
        } else if (!isNullOrUndefinedOrWhitespace(err.data)) {
          text = err.data;
        } else {
          text = defaultErrorText;
        }

        enqueueSnackbar(text, {
          variant: "error",
        });
      }
      return {
        result: null,
        status: err.status,
        hasError: true,
        error: err.data,
      };
    });
}

export function AsyncThunkFactoryPut<T>(
  id: string,
  url: string,
  successText: string,
  defaultErrorText: string,
  api?: string,
  scope?: { scopes: string[] }
) {
  return createAsyncThunk(
    id,
    async ({
      data,
      parameter,
      enqueueSnackbar,
    }: {
      data: T;
      parameter?: string;
      enqueueSnackbar: any;
    }) => {
      var hasParameter = parameter !== undefined;

      return await GenericPut<T>(
        `${api ?? apiEndpoints.originApiUrl}/${url}${
          hasParameter ? `/${parameter}` : ""
        }`,
        scope ?? msalConfig.scopes.originApiRequest,
        data,
        enqueueSnackbar,
        successText,
        defaultErrorText
      );
    }
  );
}
// #endregion

// #region Delete

export async function GenericDelete<T>(
  url: string,
  scope: { scopes: string[] },
  enqueueSnackbar: any,
  successText: string,
  defaultErrorText: string
): Promise<IResult<T>> {
  const response = await axiosInstance
    .delete(url, {
      headers: {
        Authorization: `Bearer ${await msalConfig.AccessTokenGetter(scope)}`,
      },
    })
    .then((resp) => {
      if (enqueueSnackbar) {
        enqueueSnackbar(successText, {
          variant: "success",
        });
      }
      return {
        result: resp.data,
        status: resp.status,
        hasError: false,
      };
    })
    .catch((err) => {
      if (enqueueSnackbar) {
        var text = "";

        if (!isNullOrUndefinedOrWhitespace(err.data?.title)) {
          text = err.data?.title;
        } else if (!isNullOrUndefinedOrWhitespace(err.data)) {
          text = err.data;
        } else {
          text = defaultErrorText;
        }

        enqueueSnackbar(text, {
          variant: "error",
        });
      }
      return {
        result: null,
        status: err.status,
        hasError: true,
        error: err.data,
      };
    });

  return response;
}

export function AsyncThunkFactoryDelete<T>(
  id: string,
  url: string,
  successText: string,
  defaultErrorText: string,
  api?: string,
  scope?: { scopes: string[] }
) {
  return createAsyncThunk(
    id,
    async ({
      parameter,
      enqueueSnackbar,
    }: {
      parameter?: string;
      enqueueSnackbar: any;
    }) => {
      var hasParameter = parameter !== undefined;

      var result = await GenericDelete<T>(
        `${api ?? apiEndpoints.originApiUrl}/${url}${
          hasParameter ? `/${parameter}` : ""
        }`,
        scope ?? msalConfig.scopes.originApiRequest,
        enqueueSnackbar,
        successText,
        defaultErrorText
      );

      return result;
    }
  );
}
