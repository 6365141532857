import React from "react";
import { matchPath } from "react-router-dom";

import SidebarNavListItem from "./SidebarNavListItem";
import SidebarNavList from "./SidebarNavList";
import { AccountInfoHasAuthorization } from "../Guards";

const reduceChildRoutes = (props) => {
  const { account, items, page, depth, currentRoute } = props;

  const pageCanViewContent = AccountInfoHasAuthorization(
    account,
    page.matchType,
    page.roles
  );

  if (pageCanViewContent) {
    const navListItemProps = {
      depth: depth,
      href: page.href,
      icon: page.icon,
      key: page.title,
      badge: page.badge,
      title: page.title,
    };

    if (page.children) {
      const open = page.href
        ? !!matchPath(
            {
              path: page.href,
              end: false,
            },
            currentRoute
          )
        : false;

      navListItemProps["open"] = open;
      navListItemProps["children"] = (
        <SidebarNavList depth={depth + 1} pages={page.children} />
      );
    }

    items.push(<SidebarNavListItem {...navListItemProps} />);
  }
  return items;
};

export default reduceChildRoutes;
