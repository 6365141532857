import { configureStore } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import { loggingApi } from "./apis/logging";
import { msGraphApi } from "./apis/msGraph";
import { personnelApi } from "./apis/personnel";
import coreReducer from "./slices/core";
import dashboardReducer from "./slices/dashboard";
import feedbackReducer from "./slices/feedback";
import financeReducer from "./slices/finance";
import invoicingReducer from "./slices/invoicing";
import planningReducer from "./slices/planning";
import projectsReducer from "./slices/projects";
import schedulingReducer from "./slices/scheduling";
import uiReducer from "./slices/ui";

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export const store = configureStore({
  reducer: {
    projects: projectsReducer,
    core: coreReducer,
    finance: financeReducer,
    planning: planningReducer,
    ui: uiReducer,
    invoicing: invoicingReducer,
    scheduling: schedulingReducer,
    feedback: feedbackReducer,
    dashboard: dashboardReducer,
    [personnelApi.reducerPath]: personnelApi.reducer,
    [msGraphApi.reducerPath]: msGraphApi.reducer,
    [loggingApi.reducerPath]: loggingApi.reducer,
  },

  //TODO: uncheck when planning slice has been culled a bit
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      personnelApi.middleware,
      msGraphApi.middleware,
      loggingApi.middleware
    ),
  enhancers: [sentryReduxEnhancer],
});

export const getStore = () =>
  configureStore({
    reducer: {
      projects: projectsReducer,
      core: coreReducer,
      finance: financeReducer,
      planning: planningReducer,
      ui: uiReducer,
      invoicing: invoicingReducer,
      scheduling: schedulingReducer,
      feedback: feedbackReducer,
      dashboard: dashboardReducer,
      [personnelApi.reducerPath]: personnelApi.reducer,
      [msGraphApi.reducerPath]: msGraphApi.reducer,
      [loggingApi.reducerPath]: loggingApi.reducer,
    },

    //TODO: uncheck when planning slice has been culled a bit
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(
        personnelApi.middleware,
        msGraphApi.middleware,
        loggingApi.middleware
      ),
  });

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
