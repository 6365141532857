import React from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { Button as MuiButton, Typography, Grid } from "@mui/material";
import { spacing } from "@mui/system";

const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function Page401() {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Helmet title="401 Error" />
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        401
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        No Authorization.
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        You are not authorized to view this page.
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Button
            onClick={() => navigate(-1)}
            variant="contained"
            color="secondary"
            mt={2}
          >
            Go Back
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            onClick={() => navigate("/")}
            variant="contained"
            color="secondary"
            mt={2}
          >
            Return to website
          </Button>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Page401;
